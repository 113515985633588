import { getLastCloses } from "../../streaming.js";

export const runtwinsStrike = (tvWidget) => {
	let currentDay = null;
	let lowestPrice = Infinity;
	let highestPrice = -Infinity;
	let height = Infinity;
	let halfHeight = Infinity;
	let prices = [];
	let prices1M = [];
	let rectangleDrawn = false;
	let buySignalDrawn = false;
	let sellSignalDrawn = false;
	let previousClose = null;
	let middleHigh = null;
	let stopLossHigh = null;
	let middleLow = null;
	let stopLossLow = null;
	let ohlcData1M = [];

	const init = async () => {
		const ohlcData = await getLastCloses(900, 2500);
		console.log("RUN TWINS STRIKE", new Date(ohlcData[0].time * 1000).toLocaleString());

		if (ohlcData.length > 0) {
			processData(ohlcData);
		}

		// ohlcData1M = await getLastCloses(60, 1200);
		// if (ohlcData1M.length > 0) {
		// 	processData1M(ohlcData1M);
		// }
	};
	init();

	const processData = (data) => {
		data.forEach((candle) => {
			const { time, close, open, low, high } = candle;
			const currentDate = new Date(time);
			const hour = currentDate.getUTCHours();
			const minute = currentDate.getUTCMinutes();
			const day = currentDate.getUTCDate();

			// Reiniciar los valores al comienzo de un nuevo día
			if (currentDay !== day) {
				currentDay = day;
				lowestPrice = Infinity;
				highestPrice = -Infinity;
				rectangleDrawn = false;
			}

			if (hour === 11 || hour === 12 || (hour === 13 && minute === 0)) {
				prices.push({ low: low, high: high, time: time });
			}

			if (prices.length == 9) {
				lowestPrice = Math.min(...prices.map((p) => p.low));
				highestPrice = Math.max(...prices.map((p) => p.high));
				height = highestPrice - lowestPrice;
				halfHeight = height / 2;

				const priceHigh = highestPrice + height;
				middleHigh = highestPrice + halfHeight;
				stopLossHigh = highestPrice - height;
				const priceLow = lowestPrice - height;
				middleLow = lowestPrice - halfHeight;
				stopLossLow = lowestPrice + height;
				let startTime = prices[0].time / 1000 + 0.009;
				let recStartTime = startTime + 2 * 60 * 60;
				let endTime = prices[prices.length - 1].time / 1000 + 0.009;
				let recEndTime = endTime + 12 * 60 * 60;

				//+++++++++++++++++++++++++UPPER LINE//+++++++++++++++++++++++++//
				tvWidget.activeChart().createMultipointShape(
					[
						{ time: startTime, price: highestPrice },
						{ time: endTime, price: highestPrice },
					],
					{
						shape: "trend_line",
						lock: true,
						disableSelection: true,
						disableSave: true,
						disableUndo: true,
						text: "Línea de prueba",
						overrides: {
							linecolor: "yellow",
							linewidth: 2,
							statsPosition: 3,
						},
					}
				);
				//+++++++++++++++++++++++++LOWER LINE//+++++++++++++++++++++++++//
				tvWidget.activeChart().createMultipointShape(
					[
						{ time: startTime, price: lowestPrice },
						{ time: endTime, price: lowestPrice },
					],
					{
						shape: "trend_line",
						lock: true,
						disableSelection: true,
						disableSave: true,
						disableUndo: true,
						text: "Línea de prueba",
						overrides: {
							linecolor: "yellow",
							linewidth: 2,
							statsPosition: 3,
						},
					}
				);
				//+++++++++++++++++++++++++UPPER RECTANGLE//+++++++++++++++++++++++++//
				tvWidget.activeChart().createMultipointShape(
					[
						{ time: recStartTime, price: highestPrice },
						{ time: recEndTime, price: priceHigh },
					],
					{
						shape: "rectangle",
						lock: true,
						disableSelection: true,
						disableSave: true,
						disableUndo: true,
						overrides: {
							backgroundColor: "#6495ED", // Color de fondo del rectángulo
							transparency: 50, // Transparencia del rectángulo
							bordercolor: "#6495ED", // Color del borde del rectángulo
							linewidth: 2, // Grosor del borde del rectángulo
						},
					}
				);
				tvWidget.activeChart().createMultipointShape(
					[
						{ time: recStartTime, price: middleHigh },
						{ time: recEndTime, price: middleHigh },
					],
					{
						shape: "trend_line",
						lock: true,
						disableSelection: true,
						disableSave: true,
						disableUndo: true,
						text: "Línea de prueba",
						overrides: {
							linecolor: "yellow",
							linewidth: 2,
							statsPosition: 3,
							linestyle: 1,
						},
					}
				);
				//+++++++++++++++++++++++++LOWER RECTANGLE//+++++++++++++++++++++++++//
				tvWidget.activeChart().createMultipointShape(
					[
						{ time: recStartTime, price: lowestPrice },
						{ time: recEndTime, price: priceLow },
					],
					{
						shape: "rectangle",
						lock: true,
						disableSelection: true,
						disableSave: true,
						disableUndo: true,
						overrides: {
							backgroundColor: "#5D3FD3", // Color de fondo del rectángulo
							transparency: 50, // Transparencia del rectángulo
							borderColor: "#5D3FD3", // Color del borde del rectángulo (cambiado a rojo)
							linewidth: 2, // Grosor del borde del rectángulo
						},
					}
				);
				tvWidget.activeChart().createMultipointShape(
					[
						{ time: recStartTime, price: middleLow },
						{ time: recEndTime, price: middleLow },
					],
					{
						shape: "trend_line",
						lock: true,
						disableSelection: true,
						disableSave: true,
						disableUndo: true,
						text: "Línea de prueba",
						overrides: {
							linecolor: "yellow",
							linewidth: 2,
							statsPosition: 3,
							linestyle: 1,
						},
					}
				);

				prices = [];
			}
		});
	};

	const processData1M = (data) => {
		ohlcData1M.forEach((candle) => {
			const { time, close, open, low, high } = candle;
			const currentDate = new Date(time);
			const hour = currentDate.getUTCHours();
			const minute = currentDate.getUTCMinutes();
			const day = currentDate.getUTCDate();
			console.log("DAY", day);

			if (currentDay !== day) {
				currentDay = day;
				lowestPrice = Infinity;
				highestPrice = -Infinity;
				rectangleDrawn = false;
			}

			if (hour === 11 || hour === 12 || (hour === 13 && minute === 0)) {
				prices1M.push({ low: low, high: high, time: time });
			}

			if (prices1M.length == 135) {
				console.log("PRICES", prices1M);
				console.log("PRICES 135", new Date(prices1M[0].time).toLocaleString(), new Date(prices1M[35].time).toLocaleString());
				lowestPrice = Math.min(...prices1M.map((p) => p.low));
				highestPrice = Math.max(...prices1M.map((p) => p.high));
				height = highestPrice - lowestPrice;
				halfHeight = height / 2;
				middleHigh = highestPrice + halfHeight;
				stopLossHigh = highestPrice - height;
				middleLow = lowestPrice - halfHeight;
				stopLossLow = lowestPrice + height;

				// console.log("PRICES", close, highestPrice, lowestPrice, new Date(time).toLocaleString());

				if (close > highestPrice && !buySignalDrawn) {
					if (close >= middleHigh) {
						tvWidget.activeChart().createShape(
							{ time: time / 1000, price: close },
							{
								shape: "text",
								text: "+1%",
								overrides: {
									color: "#4CAF50",
									fontsize: 25,
									bold: true,
									zOrder: "top",
								},
							}
						);
						buySignalDrawn = true;
					} else if (close <= stopLossHigh) {
						tvWidget.activeChart().createShape(
							{ time: time / 1000, price: close },
							{
								shape: "text",
								text: "-1%",
								overrides: {
									color: "#FF5252",
									fontsize: 14,
									bold: true,
								},
							}
						);
						buySignalDrawn = true;
					}
				}

				if (close < lowestPrice && !sellSignalDrawn) {
					if (close <= middleLow) {
						tvWidget.activeChart().createShape(
							{ time: time / 1000, price: close },
							{
								shape: "text",
								text: "+1%",
								overrides: {
									color: "#4CAF50",
									fontsize: 25,
									bold: true,
									zOrder: "top",
								},
							}
						);
						sellSignalDrawn = true;
					} else if (close >= stopLossLow) {
						tvWidget.activeChart().createShape(
							{ time: time / 1000, price: close },
							{
								shape: "text",
								text: "-1%",
								overrides: {
									color: "#FF5252",
									fontsize: 14,
									bold: true,
								},
							}
						);
						sellSignalDrawn = true;
					}
				}

				prices1M = [];
				buySignalDrawn = false;
				sellSignalDrawn = false;
			}
		});
	};
};
