import React, { useState, useEffect } from "react";
import { Typography, TextField, Button, FormControl, FormHelperText, Grid, Box, Paper, Select, MenuItem, InputLabel } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { store } from "../../../../store/store.js";

const UserProfileForm = ({ onHide }) => {
	const user = JSON.parse(localStorage.getItem("user"));
	const localRole = user.role === "IB";
	const [role, setRole] = useState(localRole);
	const [formData, setFormData] = useState({
		name: user.name,
		email: user.email,
		cryptoAddress: user.cryptoAddress,
		password: user.password,
		country: user.country,
		id: user.id,
	});
	const [validatePassword, setValidatePassword] = useState(user.password);
	const [errors, setErrors] = useState({});
	const [isSending, setIsSending] = useState(false);
	const history = useHistory();

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		if (name === "validatePassword") {
			setValidatePassword(value);
			return;
		}
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const validateForm = () => {
		let tempErrors = {};
		if (formData.name.trim() === "") tempErrors.name = "Nombre requerido";
		if (formData.email.trim() === "") tempErrors.email = "Email inválido";
		// if (formData.cryptoAddress.trim() === "") tempErrors.cryptoAddress = "Dirección requerida";
		if (formData.password.length < 4) tempErrors.password = "Contraseña muy corta";
		if (formData.password !== validatePassword) tempErrors.validatePassword = "Las contraseñas no coinciden";
		if (formData.country.trim() === "") tempErrors.country = "País requerido";

		setErrors(tempErrors);
		return Object.keys(tempErrors).length === 0;
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		if (!validateForm()) return;

		setIsSending(true);
		axios
			.post(`https://mitserver.app:9005/updateUser`, formData)
			.then((response) => {
				const user = JSON.parse(localStorage.getItem("user"));
				user.name = formData.name;
				user.email = formData.email;
				user.password = formData.password;
				user.country = formData.country;
				user.cryptoAddress = formData.cryptoAddress;

				localStorage.setItem("user", JSON.stringify(user));
				toast.success("Perfil actualizado con éxito");
				onHide(false);
			})
			.catch((error) => {
				console.error("Error updating user data:", error);
				toast.error("Error al actualizar el perfil");
			})
			.finally(() => {
				setIsSending(false);
			});
	};

	const handleLogout = () => {
		store.getState().reset();
		localStorage.clear();
		history.push("/login");
		window.location.reload();
	};

	const convertIB = async () => {
		const payload = { userId: user.id, email: user.email, name: user.name };
		try {
			const response = await axios.post("https://mitserver.app:9005/createIb", payload);
			if (response.status === 200) {
				toast.success(response.data.message);
				updateLocalStorage({ ...user, role: "IB" });
				setRole(true);
			} else {
				toast.error("Error al convertir a IB");
			}
		} catch (error) {
			toast.error("Error al convertir a IB");
		}
	};

	const updateLocalStorage = (user) => {
		localStorage.setItem("user", JSON.stringify(user));
		const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
		if (selectedAccount) {
			const updateAccount = {
				...selectedAccount,
				name: user.name,
				email: user.email,
				cryptoAddress: user.cryptoAddress,
				role: user.role,
				userId: user.id,
			};
			localStorage.setItem("selectedAccount", JSON.stringify(updateAccount));
		}
	};

	return (
		<div style={{ backgroundColor: "#131722" }}>
			<Typography
				sx={{
					fontFamily: "'Orbitron', sans-serif",
					fontSize: 18,
					p: 1,
					textAlign: "center",
					color: "#fff",
					textShadow: "0 0 10px #fff, 0 0 20px #fff",
				}}
				gutterBottom>
				Perfil
			</Typography>
			<form onSubmit={handleSubmit}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							fullWidth
							name="name"
							label="Nombre"
							value={formData.name}
							onChange={handleInputChange}
							error={!!errors.name}
							helperText={errors.name}
							sx={{
								"& .MuiOutlinedInput-root": {
									"& fieldset": { borderColor: "#4599d9" },
									"&:hover fieldset": { borderColor: "#f74712" },
									"&.Mui-focused fieldset": { borderColor: "#4599d9" },
								},
								"& .MuiInputLabel-root": { color: "#fff" },
								"& .MuiInputBase-input": { color: "#fff" },
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							name="email"
							label="Email"
							value={formData.email}
							onChange={handleInputChange}
							error={!!errors.email}
							helperText={errors.email}
							disabled
							sx={{
								"& .MuiOutlinedInput-root": {
									"& fieldset": { borderColor: "#4599d9" },
									"&:hover fieldset": { borderColor: "#f74712" },
									"&.Mui-focused fieldset": { borderColor: "#4599d9" },
								},
								"& .MuiInputLabel-root": { color: "#fff" },
								"& .MuiInputBase-input": { color: "#fff" },
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							type="password"
							fullWidth
							name="password"
							label="Contraseña"
							value={formData.password}
							onChange={handleInputChange}
							error={!!errors.password}
							helperText={errors.password}
							sx={{
								"& .MuiOutlinedInput-root": {
									"& fieldset": { borderColor: "#4599d9" },
									"&:hover fieldset": { borderColor: "#f74712" },
									"&.Mui-focused fieldset": { borderColor: "#4599d9" },
								},
								"& .MuiInputLabel-root": { color: "#fff" },
								"& .MuiInputBase-input": { color: "#fff" },
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							type="password"
							fullWidth
							name="validatePassword"
							label="Validar contraseña"
							value={validatePassword}
							onChange={handleInputChange}
							error={!!errors.validatePassword}
							helperText={errors.validatePassword}
							sx={{
								"& .MuiOutlinedInput-root": {
									"& fieldset": { borderColor: "#4599d9" },
									"&:hover fieldset": { borderColor: "#f74712" },
									"&.Mui-focused fieldset": { borderColor: "#4599d9" },
								},
								"& .MuiInputLabel-root": { color: "#fff" },
								"& .MuiInputBase-input": { color: "#fff" },
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<FormControl fullWidth error={!!errors.country}>
							<InputLabel id="country-label" sx={{ color: "#fff" }}>
								País
							</InputLabel>
							<Select
								labelId="country-label"
								name="country"
								value={formData.country}
								onChange={handleInputChange}
								label="País"
								sx={{
									color: "#fff",
									"& .MuiOutlinedInput-notchedOutline": { borderColor: "#4599d9" },
									"&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#f74712" },
									"&.Mui-focused .MuiOutlinedInput-notchedOutline": { borderColor: "#4599d9" },
								}}>
								<MenuItem value="">
									<em>None</em>
								</MenuItem>
								<MenuItem value="argentina">Argentina</MenuItem>
								<MenuItem value="bolivia">Bolivia</MenuItem>
								<MenuItem value="brasil">Brasil</MenuItem>
								<MenuItem value="chile">Chile</MenuItem>
								<MenuItem value="colombia">Colombia</MenuItem>
								<MenuItem value="costaRica">Costa Rica</MenuItem>
								<MenuItem value="cuba">Cuba</MenuItem>
								<MenuItem value="ecuador">Ecuador</MenuItem>
								<MenuItem value="salvador">El Salvador</MenuItem>
								<MenuItem value="guatemala">Guatemala</MenuItem>
								<MenuItem value="haiti">Haití</MenuItem>
								<MenuItem value="honduras">Honduras</MenuItem>
								<MenuItem value="mexico">México</MenuItem>
								<MenuItem value="nicaragua">Nicaragua</MenuItem>
								<MenuItem value="panama">Panamá</MenuItem>
								<MenuItem value="paraguay">Paraguay</MenuItem>
								<MenuItem value="peru">Perú</MenuItem>
								<MenuItem value="republicaDominicana">República Dominicana</MenuItem>
								<MenuItem value="uruguay">Uruguay</MenuItem>
								<MenuItem value="venezuela">Venezuela</MenuItem>
							</Select>
							<FormHelperText>{errors.country}</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={12} lg={12}>
						<Box display="flex" justifyContent="center" gap={2}>
							<Button
								variant="contained"
								type="submit"
								sx={{
									backgroundColor: "#4599d9",
									color: "#fff",
									border: "2px solid #4599d9",
									borderRadius: "15px",
									fontWeight: "bold",
									fontFamily: "'Orbitron', sans-serif",
									padding: "10px 20px",
									cursor: "pointer",
									transition: "all 0.3s ease",
									textShadow: "0 0 10px #fff",
									boxShadow: "0 0 5px #4599d9, 0 0 10px #4599d9",
									"&:hover": {
										backgroundColor: "#3d87c3",
										boxShadow: "0 0 10px #4599d9, 0 0 20px #4599d9",
									},
								}}>
								{isSending ? "Enviando..." : "Actualizar"}
							</Button>
							<Button
								variant="contained"
								onClick={handleLogout}
								sx={{
									backgroundColor: "#f74712",
									color: "#fff",
									border: "2px solid #f74712",
									borderRadius: "15px",
									fontWeight: "bold",
									fontFamily: "'Orbitron', sans-serif",
									padding: "10px 20px",
									cursor: "pointer",
									transition: "all 0.3s ease",
									textShadow: "0 0 10px #fff",
									boxShadow: "0 0 5px #f74712, 0 0 10px #f74712",
									"&:hover": {
										backgroundColor: "#d93d0d",
										boxShadow: "0 0 10px #f74712, 0 0 20px #f74712",
									},
								}}>
								Cerrar Sesión
							</Button>
						</Box>
					</Grid>
				</Grid>
			</form>
			<Typography
				variant="body1"
				sx={{
					fontFamily: "'Orbitron', sans-serif",
					color: role ? "#3B3B3B" : "white",
					marginTop: "40px",
					textAlign: "center",
					textShadow: "0 0 5px #fff",
				}}>
				TradeAI Copyright @2024 - Version 1.0.1
			</Typography>
		</div>
	);
};

export default UserProfileForm;
