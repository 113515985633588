import { Modal as BModal, Offcanvas as BOffcanvas } from "react-bootstrap";
import "./offCanvas.css";
export const Offcanvas = ({ showState, onHide, title, children, variant = "lg" }) => {
	const variants = {
		lg: "w-100 w-lg-50 my-5 rounded",
		md: "w-100 w-lg-30  my-5 rounded",
	};
	const styles = {
		lg: {
			height: "80vh", // Define un alto específico
			overflow: "auto", // Permite el desplazamiento
		},
		md: {
			height: "80vh", // Define un alto específico
			overflow: "auto", // Permite el desplazamiento
		},
	};
	const variantClass = variants[variant] || variants["lg"];
	const variantStyle = styles[variant] || styles["lg"];
	return (
		<>
			<BOffcanvas
				data-bs-theme="dark"
				show={showState}
				onHide={() => {
					onHide();
				}}
				style={{
					...variantStyle,
					backgroundColor: "#131722",
					boxShadow: "0 0 15px #4599d9, 0 0 30px #4599d9",
				}}
				className={variantClass}
				placement="end">
				<BOffcanvas.Header closeButton>
					<BOffcanvas.Title
						className={"text-center w-100"}
						style={{
							fontSize: "1.5rem",
							fontFamily: "'Orbitron', sans-serif",
							height: "1rem",
							color: "#fff",
							textShadow: "0 0 10px #4599d9, 0 0 20px #4599d9",
						}}>
						{title}
					</BOffcanvas.Title>
				</BOffcanvas.Header>
				<BOffcanvas.Body>{children}</BOffcanvas.Body>
			</BOffcanvas>
		</>
	);
};
