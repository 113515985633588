import React, { useState } from "react";
import { Modal, Box, List, ListItem, ListItemText, Typography, Grid, ListItemButton, Button, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import { store } from "../../../../store/store.js";
import axios from "axios";
import { toast } from "react-toastify";

const DrawingsPage = ({ open, onClose }) => {
	const tvWidget = store((state) => state.chart);
	const user = JSON.parse(localStorage.getItem("user"));
	const [traderToCopy, setTraderToCopy] = useState("");
	const [selectedBot, setSelectedBot] = useState(null);
	const [traderName, setTraderName] = useState("");

	const botDetails = {
		Guardar: {
			details:
				"Guarda tus gráficos y análisis técnicos personalizados para compartirlos con otros traders. Monetiza tu experiencia ofreciendo tus análisis a cambio de una tarifa. Crea una comunidad de trading y genera ingresos pasivos compartiendo tus conocimientos del mercado.",
		},
		Cargar: {
			details:
				"Carga tus gráficos y análisis técnicos guardados previamente. Accede a una biblioteca de análisis de otros traders expertos. Mejora tus estrategias de trading aprendiendo de los mejores y adaptando sus ideas a tu estilo de trading.",
		},
		Eliminar: {
			details:
				"Elimina gráficos y análisis técnicos guardados que ya no necesitas. Mantén tu espacio de trabajo organizado y enfocado en tus estrategias actuales. Asegúrate de conservar solo la información más relevante y actualizada para tus operaciones de trading.",
		},
	};

	let savedData = {};
	const saveDrawings = () => {
		const drawings = tvWidget
			.chart()
			.getAllShapes()
			.map((shape) => {
				const shapeDetails = tvWidget.chart().getShapeById(shape.id);
				const properties = shapeDetails ? shapeDetails.getProperties() : {};
				const points = shapeDetails ? shapeDetails.getPoints() : [];
				console.log("SHAPE", points);
				return {
					id: shape.id,
					name: shape.name,
					points: points.map((p) => ({ time: p.time, price: p.price })),
					properties: properties,
				};
			});

		const symbol = tvWidget.chart().symbol();
		savedData[symbol] = drawings;

		axios
			.post("https://mitserver.app:9005/saveDrawings", { userId: user.id, drawings: savedData })
			.then((response) => {
				console.log("Drawings saved successfully:", response.data);
				toast.success("Dibujos guardados exitosamente");
				onClose(true);
			})
			.catch((error) => {
				console.error("Error saving drawings:", error);
			});
	};

	const loadDrawings = (userDrawings) => {
		const symbol = tvWidget.chart().symbol();
		const payload = userDrawings ? { userId: userDrawings } : { userId: user.id };

		axios
			.post("https://mitserver.app:9005/loadDrawings", payload)
			.then((response) => {
				console.log("DRAWINGS", response);
				const drawings = response.data.drawings[symbol];
				if (drawings && drawings.length > 0) {
					tvWidget.chart().removeAllShapes();
					toast.success("Dibujos cargados exitosamente");

					drawings.forEach((drawing) => {
						const shapeOptions = drawing.properties;
						const shapePoints = drawing.points;

						if (shapePoints.length > 1) {
							tvWidget.chart().createMultipointShape(shapePoints, {
								shape: drawing.name,
								...shapeOptions,
							});
						} else if (shapePoints.length === 1) {
							tvWidget.chart().createShape(shapePoints[0], {
								shape: drawing.name,
								...shapeOptions,
							});
						} else {
							console.log(`Invalid points for drawing: ${drawing.name}`);
						}
					});
					toast.success("Graficos cargados exitosamente para el símbolo: " + symbol);
					console.log(`Drawings for symbol ${symbol} loaded:`, drawings);
					onClose(true);
				} else {
					toast.success("No hay gráficos para el símbolo: " + symbol);
					console.log(`No drawings found for symbol ${symbol}`);
					onClose(true);
				}
			})
			.catch((error) => {
				console.error("Error loading drawings:", error);
			});
	};

	const deleteDrawings = async () => {
		const symbol = tvWidget.chart().symbol();
		const payload = { userId: user.id, symbol: symbol };

		axios
			.post("https://mitserver.app:9005/removeDrawings", payload)
			.then((response) => {
				tvWidget.chart().removeAllShapes();
				toast.success("Dibujos eliminados exitosamente");
				onClose(true);
			})
			.catch((error) => {
				console.error("Error loading drawings:", error);
			});
	};

	const handleBotSelection = () => {
		if (selectedBot === "Guardar") {
			saveDrawings();
		} else if (selectedBot === "Cargar") {
			loadDrawings(traderToCopy);
		} else if (selectedBot === "Eliminar") {
			deleteDrawings();
		}
	};

	const handleFollower = (traderId) => {
		const payload = { userId: user.id, toFollow: traderId };
		axios
			.post("https://mitserver.app:9005/followDrawings", payload)
			.then((response) => {
				tvWidget.chart().removeAllShapes();
				toast.success("Dibujos eliminados exitosamente");
				onClose(true);
			})
			.catch((error) => {
				console.error("Error loading drawings:", error);
			});
	};

	const handleSearchTrader = () => {
		const payload = { userId: user.id, toFollow: traderToCopy };
		console.log("PAYLOAD", payload);
		axios
			.post("https://mitserver.app:9005/findIdDrawings", payload)
			.then((response) => {
				console.log("FIND", response);
				setTraderName(response.data.user);
			})
			.catch((error) => {
				console.error("Error loading drawings:", error);
			});
	};

	return (
		<Modal open={open} onClose={onClose}>
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: "60%",
					height: "60%",
					bgcolor: "background.paper",
					boxShadow: 24,
					p: 4,
					display: "flex",
					borderRadius: "30px",
					boxShadow: "0 0 10px #4599d9, 0 0 40px #4599d9",
				}}>
				<Typography
					variant="h4"
					sx={{
						color: "#fff",
						fontFamily: "'Orbitron', sans-serif",
						fontSize: "2rem",
						fontWeight: 700,
						textTransform: "uppercase",
						letterSpacing: "2px",
						position: "absolute",
						right: "35%",
					}}>
					Opciones de Dibujos
				</Typography>
				<Box
					sx={{
						width: "30%",
						mr: 2,
						backgroundColor: "transparent",
						overflow: "auto",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}>
					<List sx={{ padding: 0 }}>
						{Object.keys(botDetails).map((bot) => (
							<ListItem
								key={bot}
								sx={{
									mb: 4,
									borderRadius: 2,
									backgroundColor: selectedBot === bot ? "#f74712" : "#2a2e39",
									boxShadow: selectedBot === bot ? "0 0 10px #f74712, 0 0 30px #f74712" : "0 0 5px #f74712, 0 0 15px #f74712",
									transition: "all 0.3s ease",
									"&:hover": {
										transform: "scale(1.05)",
										boxShadow: "0 0 10px #f74712, 0 0 30px #f74712",
									},
								}}>
								<ListItemButton onClick={() => setSelectedBot(bot)}>
									<ListItemText
										primary={bot}
										sx={{
											color: "#fff",
											textAlign: "center",
											"& .MuiListItemText-primary": {
												fontFamily: "'Orbitron', sans-serif",
												fontSize: "1.5rem",
												fontWeight: 700,
												textTransform: "uppercase",
												letterSpacing: "2px",
											},
										}}
									/>
								</ListItemButton>
							</ListItem>
						))}
					</List>
				</Box>
				<Box
					sx={{
						width: "70%",
						backgroundColor: "transparent",
						padding: 3,
						overflow: "auto",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						height: "100%",
					}}>
					{selectedBot && (
						<>
							<Grid container>
								<Grid item xs={12}>
									<Typography
										variant="body1"
										sx={{
											color: "#fff",
											textAlign: "center",
											fontFamily: "'Orbitron', sans-serif",
											fontSize: "1rem",
											fontWeight: 500,
											letterSpacing: "1px",
											mt: 2,
										}}>
										{botDetails[selectedBot].details}
									</Typography>
								</Grid>
								{selectedBot === "Cargar" && (
									<Box sx={{ display: "flex", alignItems: "center", mt: 2, width: "100%" }}>
										<TextField
											label="Trader a copiar"
											variant="outlined"
											value={traderToCopy}
											onChange={(e) => setTraderToCopy(e.target.value)}
											sx={{ flexGrow: 1 }}
										/>
										<IconButton
											onClick={() => handleSearchTrader()}
											sx={{ ml: 1, backgroundColor: "#f74712", "&:hover": { backgroundColor: "#d93d0d" } }}>
											<SearchIcon sx={{ color: "#fff" }} />
										</IconButton>
									</Box>
								)}
								<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
									<Typography
										variant="body1"
										sx={{
											color: "#fff",
											textAlign: "center",
											fontFamily: "'Orbitron', sans-serif",
											fontSize: "1rem",
											fontWeight: 500,
											letterSpacing: "1px",
											mt: 1,
											mb: 3,
										}}>
										{traderName}
									</Typography>
								</Box>
								<Box display="flex" justifyContent="center" width="100%">
									{selectedBot === "Cargar" && (
										<Button
											variant="contained"
											onClick={() => loadDrawings()}
											sx={{
												mt: 2,
												mr: 6,
												color: "#fff",
												fontFamily: "'Orbitron', sans-serif",
												fontSize: "1.2rem",
												fontWeight: 700,
												textTransform: "uppercase",
												letterSpacing: "1px",
												backgroundColor: "#4599d9",
												boxShadow: "0 0 5px #4599d9, 0 0 15px #4599d9",
												padding: "12px 24px",
												"&:hover": {
													backgroundColor: "#2a5d8a",
												},
											}}>
											Cargar
										</Button>
									)}
									<Button
										variant="contained"
										onClick={() => handleBotSelection(selectedBot)}
										sx={{
											mt: 2,
											color: "#fff",
											fontFamily: "'Orbitron', sans-serif",
											fontSize: "1.2rem",
											fontWeight: 700,
											textTransform: "uppercase",
											letterSpacing: "1px",
											backgroundColor: "#f74712",
											boxShadow: "0 0 5px #f74712, 0 0 15px #f74712",
											padding: "12px 24px",
											"&:hover": {
												backgroundColor: "#d93d0d",
											},
										}}>
										{selectedBot === "Cargar" ? "Seguir" : selectedBot === "Eliminar" ? "Eliminar" : "Guardar"}
									</Button>
								</Box>
							</Grid>
						</>
					)}
				</Box>
			</Box>
		</Modal>
	);
};

export default DrawingsPage;
