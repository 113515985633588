import { getLastCloses } from "../../streaming.js";

export const runLogaritmic = (tvWidget) => {
	console.log("RUN LOGARITMIC");
	const init = async () => {
		const ohlcData = await getLastCloses(86400, 300);
		console.log("RUN LOGARITMIC", new Date(ohlcData[0].time * 1000).toLocaleString());

		if (ohlcData.length > 0) {
			processData(ohlcData);
		}
	};
	init();

	const processData = (prices) => {
		for (let i = 29; i < prices.length; i++) {
			const currentDate = new Date(prices[i].time);
			const relevantPrices = prices.slice(i - 29, i + 1);
			const closePrices = relevantPrices.map((priceData) => priceData.close);
			const logReturns = closePrices.slice(1).map((price, index) => Math.log(price / closePrices[index]));
			const sumLogReturns = logReturns.reduce((acc, val) => acc + val, 0);
			const meanLogReturn = (sumLogReturns / logReturns.length) * 100;
			const varianceLogReturn = logReturns.map((r) => Math.pow(r - meanLogReturn, 2)).reduce((acc, val) => acc + val, 0) / logReturns.length;
			const dailyVolatility = Math.sqrt(varianceLogReturn);
			const annualVolatility = (dailyVolatility / Math.sqrt(252)) * 100;
			const lastClosePrice = closePrices[closePrices.length - 2];
			const projectedPrices = {
				Z1P: lastClosePrice + lastClosePrice * ((dailyVolatility * 1.316) / 100),
				Z2P: lastClosePrice + lastClosePrice * ((dailyVolatility * 2.129) / 100),
				Z3P: lastClosePrice + lastClosePrice * ((dailyVolatility * 3.444) / 100),
				Z1N: lastClosePrice - lastClosePrice * ((dailyVolatility * 1.316) / 100),
				Z2N: lastClosePrice - lastClosePrice * ((dailyVolatility * 2.129) / 100),
				Z3N: lastClosePrice - lastClosePrice * ((dailyVolatility * 3.444) / 100),
			};
			drawTrendLines(tvWidget, currentDate, projectedPrices);
		}
	};

	const drawTrendLines = (tvWidget, date, projectedPrices) => {
		const startTime = new Date(date);
		startTime.setUTCHours(1, 0, 0, 0);
		const endTime = new Date(date);
		endTime.setUTCHours(23, 0, 0, 0);
		Object.entries(projectedPrices).forEach(([key, value]) => {
			tvWidget.activeChart().createMultipointShape(
				[
					{ time: startTime.getTime() / 1000, price: value },
					{ time: endTime.getTime() / 1000, price: value },
				],
				{
					shape: "trend_line",
					lock: true,
					disableSelection: true,
					disableSave: true,
					disableUndo: true,
					// text: key,
					overrides: {
						linecolor: key.includes("P") ? "#00FF00" : "#FF0000",
						linewidth: 2,
						linestyle: 0,
						showLabel: true,
						textcolor: "#FFFFFF",
						fontsize: 12,
						fontfamily: "Verdana",
						bold: true,
						transparency: 50,
					},
				}
			);
		});
	};
};
