import { store } from "../../store/store";
import socket from "../../socket/Socket.js";
import { toast } from "react-toastify";
import axios from "axios";
import { translations } from "./pages/components/Draggable/Draggable.component.jsx";

console.log("STARTING STREAMING");

// const appId = "62094";
const appId = 62094;
// const appId = 39953;
const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
const chartType = localStorage.getItem("chartType");
export let derivWS = new WebSocket("wss://ws.derivws.com/websockets/v3?app_id=" + appId);
store.getState().setDerivWS(derivWS);
const connectionDStatus = document.getElementById("socketDeriv");
const subscriptions = new Map();
const ping_interval = 12000;
var reconnectInterval = 1000;
let interval;
let params = null;
let activeSymbol = {};
const apiUrl = process.env.REACT_APP_API_URL;
let symbolsCache = null;
let multiplierAssets = [];
let oldPeriod = null;
let hasCalledDerivWS = false;
const processedContracts = new Set();
let accumHighLine = null;
let accumLowLine = null;
let accumRect = null;
let previousBar = null;
let circleShape = null;

function initDerivWSConnection() {
	console.log("INITIALIZING DERIV WS", derivWS.readyState, derivWS);

	// Create a new WebSocket instance
	derivWS = new WebSocket("wss://ws.derivws.com/websockets/v3?app_id=" + appId);
	store.getState().setDerivWS(derivWS);

	derivWS.addEventListener("open", async (event) => {
		console.log("+++++++++++++++++++++ DERIVWS OPEN +++++++++++++++++++++", event);
		connectionDStatus.style.backgroundColor = "green";
		const sendMessage = JSON.stringify({ ping: 1 });
		derivWS.send(sendMessage);
		reconnectInterval = 1000;

		if (selectedAccount && selectedAccount.token) {
			const authMessage = await JSON.stringify({ authorize: selectedAccount.token, passthrough: { balance: true } });
			derivWS.send(authMessage);
		}
	});

	derivWS.addEventListener("close", (event) => {
		console.log("DERIVWS CLOSE", event);
		connectionDStatus.style.backgroundColor = "red";
		reconnectWebSocket();
	});

	derivWS.addEventListener("error", (event) => {
		console.log("DERIVWS ERROR", event);
		connectionDStatus.style.backgroundColor = "red";
		reconnectWebSocket();
	});
}

initDerivWSConnection();

derivWS.addEventListener("message", async (event) => {
	try {
		const receivedMessage = JSON.parse(event.data);
		if (receivedMessage.msg_type !== "ohlc") {
			// console.log("DERIVSOCKET", receivedMessage);
		}

		if (receivedMessage.error) {
			toast.error(translations[receivedMessage.error.message], {
				position: "top-right",
				autoClose: 2000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				theme: "dark",
			});
			return;
		}

		if (receivedMessage.msg_type === "ohlc") {
			const chartType = localStorage.getItem("chartType");
			const ohlc = receivedMessage.ohlc;
			if (ohlc.symbol === activeSymbol.symbol && ohlc.granularity === activeSymbol.period) {
				const symbol = ohlc.symbol;
				const subs = subscriptions.get(symbol);
				if (!subs) {
					console.warn(`No subscription found for symbol: ${symbol}`);
					return;
				}

				let epochTime = (ohlc.epoch * 1000).toString();

				if (epochTime.length < 13) {
					const difference = 13 - epochTime.length;
					epochTime = epochTime + "0".repeat(difference);
				}

				const formattedTimeString = epochTime + ".000";
				const formattedTime = parseFloat(formattedTimeString);
				let bar = ohlc;
				let currentBar = {
					epoch: epochTime,
					time: formattedTime,
					open: parseFloat(ohlc.open),
					close: parseFloat(ohlc.close),
					high: parseFloat(ohlc.high),
					low: parseFloat(ohlc.low),
				};

				if (chartType === "Opciones") {
					// setTimeout(() => {
					subs.onRealtimeCallback(currentBar);
					// }, 1000);
				} else {
					subs.onRealtimeCallback(currentBar);
				}

				// const tvWidget = store.getState().chart;
				// console.log("DERIVSOCKET", tvWidget);
				// if (circleShape) {
				// 	tvWidget.activeChart().removeEntity(circleShape);
				// }

				// circleShape = tvWidget.activeChart().createMultipointShape(
				// 	[
				// 		{ time: ohlc.epoch, price: parseFloat(ohlc.close) },
				// 		{ time: ohlc.epoch + 3, price: parseFloat(ohlc.close) },
				// 	],
				// 	{
				// 		shape: "circle",
				// 		text: `${parseFloat(ohlc.close).toFixed(2)}`,
				// 		overrides: {
				// 			backgroundColor: "rgba(0, 100, 255, 0.3)",
				// 			color: "rgba(0, 100, 255, 1)",
				// 			borderWidth: 2,
				// 			textColor: "white",
				// 			fontsize: 14,
				// 			bold: true,
				// 			zIndex: 100,
				// 		},
				// 		zOrder: "top",
				// 	}
				// );

				store.getState().setLastBar(ohlc);
			}
		} else if (receivedMessage.msg_type === "authorize") {
			if (receivedMessage.echo_req.passthrough) {
				if (receivedMessage.echo_req.passthrough.contractId) {
					const data = receivedMessage.echo_req.passthrough;
					const proposalMessage = JSON.stringify({
						proposal_open_contract: 1,
						contract_id: data.contractId,
						subscribe: 1,
					});
					derivWS.send(proposalMessage);
				} else if (receivedMessage.echo_req.passthrough.balance) {
					const balanceMessage = JSON.stringify({
						balance: 1,
						subscribe: 1,
					});
					derivWS.send(balanceMessage);
				} else if (receivedMessage.echo_req.passthrough.history) {
					const historyMessage = JSON.stringify({
						proposal_open_contract: 1,
						subscribe: 1,
						passthrough: { history: true },
					});
					derivWS.send(historyMessage);
				}
			}
		} else if (receivedMessage.msg_type === "proposal_open_contract") {
			// console.log("PROPOSAL OPEN CONTRACT", receivedMessage.proposal_open_contract);
			const optionType = await localStorage.getItem("optionType");

			if (
				(chartType === "Opciones" &&
					receivedMessage.proposal_open_contract.contract_type !== "MULTUP" &&
					receivedMessage.proposal_open_contract.contract_type !== "MULTDOWN") ||
				(receivedMessage.proposal_open_contract.contract_type !== "MULTUP" &&
					receivedMessage.proposal_open_contract.contract_type !== "MULTDOWN")
			) {
				const tvWidget = store.getState().chart;
				let lastDrawnTick = null;
				const proposal = receivedMessage.proposal_open_contract;
				let barrierLine = null;
				let startLine = null;

				if (!processedContracts.has(proposal.contract_id)) {
					processedContracts.add(proposal.contract_id);

					proposal.tick_stream.forEach((tick, index) => {
						const resultColor = "orange";

						if (lastDrawnTick) {
							tvWidget.activeChart().createMultipointShape(
								[
									{ time: lastDrawnTick.epoch, price: lastDrawnTick.tick },
									{ time: tick.epoch, price: tick.tick },
								],
								{
									shape: "trend_line",
									lock: true,
									disableSelection: true,
									disableSave: true,
									disableUndo: true,
									text: `Tick ${index}`,
									overrides: {
										linecolor: "orange",
										linewidth: 5,
										linestyle: 1, // Changed to dotted line
										statsPosition: 3,
										textcolor: "white",
										fontsize: 12,
										bold: true,
									},
								}
							);

							if (optionType !== "accumulators") {
								//BARRIER LINE
								if (!barrierLine) {
									barrierLine = tvWidget.activeChart().createMultipointShape(
										[
											{ time: proposal.entry_tick_time, price: Number(proposal.barrier) },
											{ time: proposal.entry_tick_time + proposal.tick_count, price: Number(proposal.barrier) },
										],
										{
											shape: "trend_line",
											lock: true,
											disableSelection: true,
											disableSave: true,
											disableUndo: true,
											overrides: {
												linecolor: resultColor,
												linewidth: 0.5,
												statsPosition: 3,
												textcolor: "white",
												fontsize: 12,
												bold: true,
											},
										}
									);
								}

								//START LINE
								if (!startLine) {
									startLine = tvWidget.activeChart().createMultipointShape(
										[
											{ time: proposal.entry_tick_time, price: proposal.entry_spot },
											{ time: proposal.entry_tick_time, price: Number(proposal.barrier) },
										],
										{
											shape: "trend_line",
											lock: true,
											disableSelection: true,
											disableSave: true,
											disableUndo: true,
											overrides: {
												linecolor: resultColor,
												linestyle: 2, // Dotted line
												linewidth: 1,
											},
										}
									);
								}
							} else {
								// tvWidget.activeChart().createShape(
								// 	{ time: proposal.sell_time, price: proposal.sell_spot },
								// 	{
								// 		shape: "balloon",
								// 		text: proposal.profit,
								// 		overrides: {
								// 			backgroundColor: resultColor,
								// 			color: "white",
								// 			fontsize: 20,
								// 			bold: true,
								// 			zIndex: 100,
								// 		},
								// 		zOrder: "top",
								// 	}
								// );
							}
						}
						lastDrawnTick = tick;
					});

					const expireTime = proposal.expiry_time;
					const currentTime = proposal.current_spot_time;

					if (expireTime === currentTime) {
						const forgetMessage = JSON.stringify({
							forget: proposal.id,
							passthrough: { contractId: proposal.contract_id },
						});
						derivWS.send(forgetMessage);

						const sellExpiredMessage = JSON.stringify({
							sell_expired: 1,
							passthrough: { contractId: proposal.contract_id },
						});
						derivWS.send(sellExpiredMessage);
					}
				} else {
					if (proposal.status === "won" || proposal.status === "lost") {
						const forgetMessage = JSON.stringify({ forget: proposal.id });
						derivWS.send(forgetMessage);
						const resultColor = proposal.status === "won" ? "green" : "red";
						const optionType = await localStorage.getItem("optionType");

						if (optionType !== "accumulators") {
							//BARRIER LINE
							barrierLine = tvWidget.activeChart().createMultipointShape(
								[
									{ time: proposal.entry_tick_time, price: Number(proposal.barrier) },
									{ time: proposal.expiry_time, price: Number(proposal.barrier) },
								],
								{
									shape: "trend_line",
									lock: true,
									disableSelection: true,
									disableSave: true,
									disableUndo: true,
									overrides: {
										linecolor: resultColor,
										linewidth: 0.5,
										statsPosition: 3,
										textcolor: "white",
										fontsize: 12,
										bold: true,
									},
								}
							);

							//START LINE
							startLine = tvWidget.activeChart().createMultipointShape(
								[
									{ time: proposal.entry_tick_time, price: proposal.entry_spot },
									{ time: proposal.entry_tick_time, price: Number(proposal.barrier) },
								],
								{
									shape: "trend_line",
									lock: true,
									disableSelection: true,
									disableSave: true,
									disableUndo: true,
									overrides: {
										linecolor: resultColor,
										linestyle: 2, // Dotted line
										linewidth: 1,
									},
								}
							);

							//END LINE
							tvWidget.activeChart().createMultipointShape(
								[
									{ time: proposal.sell_time, price: Number(proposal.barrier) },
									{ time: proposal.sell_time, price: proposal.sell_spot },
								],
								{
									shape: "trend_line",
									lock: true,
									disableSelection: true,
									disableSave: true,
									disableUndo: true,
									overrides: {
										linecolor: resultColor,
										linestyle: 2, // Dotted line
										linewidth: 1,
									},
								}
							);
						}
						//BALLON TEXT
						tvWidget.activeChart().createShape(
							{ time: proposal.sell_time, price: proposal.sell_spot },
							{
								shape: "balloon",
								text: proposal.profit,
								lock: true,
								disableSelection: true,
								disableSave: true,
								disableUndo: true,
								overrides: {
									backgroundColor: resultColor,
									color: "white",
									fontsize: 20,
									bold: true,
									zIndex: 100,
								},
								zOrder: "top",
							}
						);

						hasCalledDerivWS = false;
					}
					processedContracts.delete(proposal.contract_id);
				}
			}
		} else if (receivedMessage.msg_type === "sell_expired") {
			const contractId = receivedMessage.echo_req.passthrough.contractId;
			const proposalMessage = JSON.stringify({
				proposal_open_contract: 1,
				contract_id: contractId,
				subscribe: 1,
			});
			derivWS.send(proposalMessage);
		} else if (receivedMessage.msg_type === "forget") {
			// console.log("FORGET", receivedMessage);
		} else if (receivedMessage.msg_type === "balance") {
			const balance = receivedMessage.balance.balance;
			store.getState().setBalance(Number(balance).toFixed(2));
		} else if (receivedMessage.msg_type === "proposal") {
			const data = receivedMessage.echo_req.passthrough;

			if (data && data.accumulators) {
				const contractDetails = receivedMessage.proposal.contract_details;

				const ticksStayIn = contractDetails.ticks_stayed_in;
				const barrierDistance = contractDetails.barrier_spot_distance;
				localStorage.setItem("barrierDistance", barrierDistance);
				store.getState().setTickCount(ticksStayIn[0]);
				const subscriptionId = receivedMessage.proposal.id;
				store.getState().setProposalId(subscriptionId);

				setTimeout(() => {
					const highBarrier = contractDetails.high_barrier;
					const lowBarrier = contractDetails.low_barrier;
					drawAccumLines(highBarrier, lowBarrier, barrierDistance);
				}, 1300);
			}
		}
	} catch (error) {
		console.log("DERIVWS ERROR", error);
	}
});

derivWS.addEventListener("close", (event) => {
	console.log("DERIVWS CLOSE", event);
	connectionDStatus.style.backgroundColor = "red";
	reconnectWebSocket();
});

derivWS.addEventListener("error", (event) => {
	console.log("DERIVWS ERROR", event);
	connectionDStatus.style.backgroundColor = "red";
	reconnectWebSocket();
});

derivWS.addEventListener("disconnect", (event) => {
	console.log("DERIVWS DISCONNECTED", event);
	connectionDStatus.style.backgroundColor = "red";
	reconnectWebSocket();
});

const reconnectWebSocket = () => {
	const maxRetries = 8;
	const retryDelay = 4000;
	let retryCount = 0;

	const tryReconnect = () => {
		if (retryCount >= maxRetries) {
			console.log("Max reconnection attempts reached");
			return;
		}

		if (derivWS.readyState === WebSocket.CLOSED) {
			console.log(`Attempting reconnection #${retryCount + 1}`);
			retryCount++;
			initDerivWSConnection();

			setTimeout(() => {
				console.log(`Reconnection attempt ${retryCount} completed`);
				tryReconnect();
			}, retryDelay);
		} else {
			console.log("WebSocket is not in CLOSED state, current state:", derivWS.readyState);
		}
	};

	tryReconnect();
};

const waitForConnection = (callback) => {
	if (derivWS.readyState === WebSocket.OPEN) {
		callback();
	} else {
		reconnectWebSocket();
		setTimeout(() => {
			waitForConnection(callback);
		}, 1000);
	}
};

export function getPeriod(resolution) {
	const resolutions = ["1", "2", "3", "5", "15", "30", "60", "120", "240", "1D"];
	const resolutionValues = [60, 120, 180, 300, 900, 1800, 3600, 7200, 14400, 86400];
	const index = resolutions.indexOf(resolution);
	if (index === -1) {
		return 60; // Default value
	}
	return resolutionValues[index];
}

const getSymbolsInfo = async (symbol1) => {
	try {
		if (symbolsCache) {
			return symbolsCache.find((symbol) => symbol.code === symbol1);
		}

		const response = await axios.post(`https://mitserver.app:9005/getList`, {
			table: "symbols",
		});

		if (response.status === 200) {
			symbolsCache = response.data;
			const selectedSymbol = symbolsCache.find((symbol) => symbol.code === symbol1);
			return selectedSymbol;
		}
	} catch (error) {
		throw error;
	}
};

export async function setSymbolStorage(symbolItem) {
	try {
		const additionalSymbolInfo = await getSymbolsInfo(symbolItem.symbol);
		const { multiplier2, multiplier3, derivMultiplier, derivMultiplier2, derivMultiplier3, pipValue, stopOut, tickSize } = additionalSymbolInfo;

		var symboltoDrag = {
			type: symbolItem.type || symbolItem.market,
			displayName: symbolItem.ticker || symbolItem.display_name,
			symbol: symbolItem.symbol,
			pip: symbolItem.pip || tickSize,
			exchangeOpen: symbolItem.exchangeOpen || 1,
			submarket: symbolItem.submarket,
			ticker: symbolItem.ticker || symbolItem.display_name,
			exchange: "MIT",
			contractSize: symbolItem.contractSize || symbolItem.display_name.toLowerCase().includes("step") ? 10 : 1,
			derivMultiplier,
			derivMultiplier2,
			derivMultiplier3,
			multiplier2,
			multiplier3,
			pipValue,
			stopOut,
		};
		localStorage.setItem("symbolInfo", JSON.stringify(symboltoDrag));
		store.getState().setSymbolInfo(symboltoDrag);
		// return symboltoDrag;
	} catch (error) {
		toast.error("Hubo un problema al cargar el simbolo, Reintentando ...", {
			position: "bottom-right",
			autoClose: 1000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			theme: "dark",
			progressStyle: { background: "#f74712" },
		});
		const repeat = symbolItem;
		setTimeout(() => {
			setSymbolStorage(repeat);
		}, 500);
	}
}

export async function getHistoryDWS(derivData) {
	const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
	const tvWidget = store.getState().chart;
	params = derivData;
	let per = derivData.period;

	if (oldPeriod === "1T") {
		tvWidget.activeChart().setChartType(1);
	}

	if (!derivData.firstTime && derivData.period === "1T") {
		tvWidget.activeChart().setChartType(3);
		oldPeriod = "1T";
	} else {
		oldPeriod = derivData.period;
	}

	const period = getPeriod(per);
	let message = {};
	if (derivData.firstTime) {
		if (derivData.period === "1T") {
			message = JSON.stringify({
				ticks_history: derivData.symbol,
				adjust_start_time: 1,
				count: 2000,
				end: "latest",
				style: "ticks",
			});
		} else {
			message = JSON.stringify({
				ticks_history: derivData.symbol,
				adjust_start_time: 1,
				count: 2000,
				end: "latest",
				style: "candles",
				granularity: period,
			});
		}

		let allPos = {
			symbol: derivData.symbol,
			userId: selectedAccount.userId,
			account: selectedAccount.account,
		};

		socket.emit("allLines", allPos);
	} else {
		let start = derivData.from;
		let end = derivData.to;
		if (derivData.period === "1T") {
			start = Math.round(new Date(derivData.from).getTime() / 1000);
			end = Math.round(new Date(derivData.to).getTime() / 1000);
		}

		if (derivData.period === "1T") {
			message = JSON.stringify({
				ticks_history: derivData.symbol,
				adjust_start_time: 1,
				start: start,
				end: end,
				style: "ticks",
			});
		} else {
			message = JSON.stringify({
				ticks_history: derivData.symbol,
				adjust_start_time: 1,
				start: start,
				end: end,
				style: "candles",
				granularity: period,
			});
		}
	}

	derivWS.send(message);
	let candles = null;

	if (derivData.period === "1T") {
		const tempCandles = await waitForMessageOfType("history");
		const history = tempCandles.history;
		candles = history.prices.map((price, index) => ({
			close: price,
			epoch: history.times[index],
		}));
	} else {
		const tempCandles = await waitForMessageOfType("candles");
		candles = tempCandles.candles;
	}
	return candles;
}

export function waitForMessageOfType(messageType) {
	return new Promise((resolve, reject) => {
		derivWS.onmessage = async (event) => {
			const receivedMessage = JSON.parse(event.data);
			try {
				switch (receivedMessage.msg_type) {
					case messageType:
						resolve(receivedMessage);
						break;
					default:
						// console.log(`DEFAULT MESSAGE WSDERIV ${JSON.stringify(receivedMessage)}`);
						break;
				}
			} catch (error) {
				reject(error);
			}
		};
	});
}

export function getSymbols() {
	return new Promise(async (resolve, reject) => {
		try {
			const symbolsType = await localStorage.getItem("symbolsType");
			const waitForConnection = (socket, timeout = 1000) => {
				return new Promise((resolve, reject) => {
					const maxAttempts = 5;
					let attempts = 0;

					const checkConnection = () => {
						attempts++;

						if (socket.readyState === WebSocket.OPEN) {
							resolve();
						} else if (attempts >= maxAttempts) {
							reject(new Error("Max connection attempts reached"));
						} else {
							setTimeout(checkConnection, timeout);
						}
					};

					checkConnection();
				});
			};

			try {
				await waitForConnection(derivWS);
				derivWS.send(
					JSON.stringify({
						active_symbols: "brief",
						product_type: "basic",
						contract_type: symbolsType ? [symbolsType] : ["MULTUP"],
					})
				);
			} catch (error) {
				console.log("Connection failed:", error);
				reconnectWebSocket();
			}

			const handleData = (event) => {
				const msgData = JSON.parse(event.data);
				const msgType = msgData.msg_type;

				if (msgType === "active_symbols") {
					const activeSymbols = msgData.active_symbols;
					const completeSymbols = activeSymbols.map((symbol) => ({
						type: symbol.market,
						displayName: symbol.display_name,
						symbol: symbol.symbol,
						pip: symbol.pip,
						exchangeOpen: symbol.exchange_is_open,
						submarket: symbol.submarket,
						ticker: symbol.display_name,
						exchange: "MIT",
						contractSize: symbol.market === "forex" ? 100000 : 1,
					}));

					derivWS.removeEventListener("message", handleData);
					derivWS.removeEventListener("error", handleError);

					store.getState().setSymbols(completeSymbols);
					resolve(completeSymbols);
				}
			};

			const handleError = (error) => {
				console.log("DERIVWS ERROR GET SYMBOLS", error);
				derivWS.removeEventListener("message", handleData);
				derivWS.removeEventListener("error", handleError);
				reject(error);
			};

			derivWS.addEventListener("message", handleData);
			derivWS.addEventListener("error", handleError);

			derivWS.onerror = function (err) {
				console.log("DERIVWS GET SYMBOL ERROR", err);
				reject(err);
			};
		} catch (err) {
			reject(err);
		}
	});
}
export function subscribeOnStream(symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) {
	var period = getPeriod(resolution);
	activeSymbol.symbol = symbolInfo.ticker;
	activeSymbol.period = period;
	const subscriptionItem = {
		symbol: symbolInfo.ticker,
		period: period,
		onRealtimeCallback: onRealtimeCallback,
		subscriptionId: "",
	};

	subscriptions.set(symbolInfo.ticker, subscriptionItem);
	const message = JSON.stringify({
		ticks_history: symbolInfo.ticker,
		adjust_start_time: 1,
		count: 2002,
		end: "latest",
		style: "candles",
		granularity: period,
		subscribe: 1,
	});
	derivWS.send(message);

	// setTimeout(() => {
	// 	const tvWidget = store.getState().chart;
	// 	tvWidget.activeChart().setSymbol("EUR/USD");
	// }, 3000);
	// setTimeout(() => {
	// 	const tvWidget = store.getState().chart;
	// 	tvWidget.activeChart().setSymbol("EUR/GBP");
	// }, 9000);
}

export function unsubscribeFromStream() {
	subscriptions.forEach((subscriptionItem, symbol) => {
		if (subscriptionItem.symbol !== activeSymbol.symbol) {
			derivWS.send(
				JSON.stringify({
					forget: subscriptionItem.subscriptionId,
				})
			);
			subscriptions.delete(symbol);
		}
	});
}

export async function getLastCloses(granularity, count) {
	try {
		let candles;
		if (granularity === "1T") {
			const message = JSON.stringify({
				ticks_history: activeSymbol.symbol,
				adjust_start_time: 1,
				count: count,
				end: "latest",
				style: "ticks",
			});
			derivWS.send(message);
			const ticks = await waitForMessageOfType("history");
			const history = ticks.history;
			return history.prices;
		}
		const message = JSON.stringify({
			ticks_history: activeSymbol.symbol,
			adjust_start_time: 1,
			count: count,
			end: "latest",
			style: "candles",
			granularity: granularity,
		});

		derivWS.send(message);
		candles = await waitForMessageOfType("candles");

		if (!candles || !candles.candles) {
			console.error("No se recibieron datos de velas");
			return [];
		}

		const filteredCandles = candles.candles.map((candle) => ({
			close: candle.close,
			high: candle.high,
			low: candle.low,
			open: candle.open,
			time: candle.epoch * 1000,
		}));
		return filteredCandles;
	} catch (error) {
		console.error(error.message);
		return [];
	}
}

export async function getShocks() {
	const proposalId = store.getState().proposalId;
	if (proposalId !== null) {
		derivWS.send(JSON.stringify({ forget: proposalId }));
		store.getState().setProposalId(null);
		await new Promise((resolve) => setTimeout(resolve, 250));
		return getShocks();
	}

	try {
		const [riskAmount, rate, symbolInfo] = await Promise.all([
			localStorage.getItem("riskAmount"),
			localStorage.getItem("sliderValue"),
			store.getState().symbolInfo,
		]);

		const callMessage = JSON.stringify({
			proposal: 1,
			amount: riskAmount,
			growth_rate: rate / 100,
			basis: "stake",
			contract_type: "ACCU",
			currency: "USD",
			symbol: symbolInfo.symbol,
			subscribe: 1,
			passthrough: { accumulators: true },
		});
		console.log(" GET SHOCKS", callMessage);
		await derivWS.send(callMessage);
		const callProposal = await waitForMessageOfType("proposal");
		return callProposal.proposal.contract_details.ticks_stayed_in;
	} catch (error) {
		console.error(error.message);
		return [];
	}
}

export async function calcBarrier(distance, amount, symbol, slider) {
	const sendMessage = JSON.stringify({
		proposal: 1,
		amount: Number(amount),
		barrier: distance,
		basis: "payout",
		contract_type: "CALL",
		currency: "USD",
		duration: Number(slider),
		duration_unit: "s",
		symbol: symbol,
	});
	derivWS.send(sendMessage);
	const proposal = await waitForMessageOfType("proposal");
	console.log("PROPOSAL", proposal);
	if (proposal.error) {
		return proposal.error;
	} else {
		return proposal.proposal;
	}
}

function waitForSymbolInfo(maxRetries = 10, delay = 500) {
	return new Promise((resolve, reject) => {
		let retries = 0;

		const checkSymbolInfo = () => {
			const symbolInfo = store.getState().symbolInfo;

			if (symbolInfo && symbolInfo.symbol) {
				resolve(symbolInfo);
			} else if (retries < maxRetries) {
				retries++;
				setTimeout(checkSymbolInfo, delay);
			} else {
				reject(new Error("symbolInfo no está disponible después de varios intentos"));
			}
		};

		checkSymbolInfo();
	});
}

export function handleOpenOption(option) {
	if (!hasCalledDerivWS) {
		const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
		const authMessage = JSON.stringify({ authorize: selectedAccount.token, passthrough: { contractId: option.contractId } });
		derivWS.send(authMessage);
		hasCalledDerivWS = true;
	}
}

const drawAccumLines = async (highPrice, lowPrice, barrierDistance) => {
	const optionType = await localStorage.getItem("optionType");

	if (optionType === "accumulators") {
		const tvWidget = store.getState().chart;

		if (tvWidget) {
			if (accumHighLine) {
				accumHighLine.setPrice(highPrice).setText(`Barrera`).setQuantity(barrierDistance).setExtendLeft(false);
			} else {
				accumHighLine = tvWidget
					.chart()
					.createOrderLine()
					.setText(`High`)
					.setQuantity(barrierDistance)
					.setPrice(highPrice)
					.setLineColor("#4CAF50")
					.setBodyTextColor("#4CAF50")
					.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
					.setQuantityBackgroundColor("rgba(0, 0, 0, 0)")
					.setExtendLeft(false)
					.setLineLength(30, "percentage");
			}

			if (accumLowLine) {
				accumLowLine.setPrice(lowPrice).setText(`Barrera`).setQuantity(barrierDistance).setExtendLeft(false);
			} else {
				accumLowLine = tvWidget
					.chart()
					.createOrderLine()
					.setText(`Low`)
					.setQuantity(barrierDistance)
					.setPrice(lowPrice)
					.setLineColor("#4CAF50")
					.setBodyTextColor("#4CAF50")
					.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
					.setQuantityBackgroundColor("rgba(0, 0, 0, 0)")
					.setExtendLeft(false)
					.setLineLength(30, "percentage");
			}
		}
	}
};

export const removeAccumLines = () => {
	const tvWidget = store.getState().chart;

	if (tvWidget) {
		if (accumHighLine) {
			accumHighLine.remove();
			accumHighLine = null;
		}
		if (accumLowLine) {
			accumLowLine.remove();
			accumLowLine = null;
		}
	}
};
