import React, { useState, useEffect } from "react";
import { Modal, Box, List, ListItem, ListItemText, Typography, Grid, ListItemButton, Button, TextField } from "@mui/material";
import { store } from "../../../../store/store.js";
import { toast } from "react-toastify";

import { runLogaritmic } from "./logaritmic";
import { runtwinsStrike } from "./twinsStrike";
import { runAccumulators } from "./mlAccumulators";
import { runTouchNoTouch } from "./mlTouchNoTouch.js";
import { runHigherLower } from "./mlHigherLower.js";
import { runRiseFall } from "./mlRiseFall";

const BotsPage = ({ open, onClose }) => {
	const tvWidget = store((state) => state.chart);
	const profitTicks = localStorage.getItem("profitTick");
	const chartType = localStorage.getItem("chartType");
	const optionType = localStorage.getItem("optionType");
	const ticks = localStorage.getItem("sliderValue");
	const localBarrier = localStorage.getItem("barrier");
	const [selectedBot, setSelectedBot] = useState("");
	const [windowSize, setWindowSize] = useState(80);
	const [predictionTicks, setPredictionTicks] = useState("");
	const [barrier, setBarrier] = useState(localBarrier || "");
	const [epochs, setEpochs] = useState(3);
	let botDetails = "Multiplicadores";

	useEffect(() => {
		setPredictionTicks(profitTicks ? profitTicks : 5);
	}, [profitTicks]);

	if (chartType === "Multiplicadores") {
		botDetails = {
			Logaritmic: {
				images: ["/assets/logaritmic.png"],
				details: "Un bot de trading diseñado con los retornos logaritmicos diarios y aprovechar los retrocesos de los mercados.",
			},
			TwinStrike: {
				images: ["/assets/twinsStrike.png"],
				details:
					"Un bot de trading diseñado para detectar y aprovechar oportunidades clave en un rango específico de tiempo, utilizando estrategias de entradas múltiples para optimizar el rendimiento.",
			},
			ProfitBoost: {
				images: ["/assets/profitBoost.jpg"],
				details: "Proximamente disponible",
			},
		};
	} else if (chartType === "Opciones") {
		botDetails = {
			"Rise Fall": {
				images: ["/assets/riseFall.webp"],
				details:
					"Un bot de trading avanzado que combina predicciones de dirección de precio con técnicas de machine learning. Utiliza análisis predictivo para determinar si el precio cerrará por encima o por debajo del nivel actual, optimizando las entradas en el mercado.",
			},
			"Higher Lower": {
				images: ["/assets/higherLower.webp"],
				details:
					"Un bot de trading basado en machine learning diseñado para predecir movimientos de precio a corto plazo, analizando patrones en intervalos de 5 ticks para optimizar entradas y salidas en el mercado.",
			},
			"Touch No Touch": {
				images: ["/assets/touchNoTouch.webp"],
				details:
					"Un bot de trading basado en machine learning diseñado para predecir movimientos de precio a corto plazo, analizando patrones en intervalos de 5 ticks para optimizar entradas y salidas en el mercado.",
			},
			Accumulators: {
				images: ["/assets/accumulators.webp"],
				details:
					"Un bot de trading cuantitativo que implementa cálculos probabilísticos avanzados y análisis de volatilidad mediante modelos estocásticos para operar con barreras optimizando estrategias a través de técnicas estadísticas.",
			},
		};
	}

	const handleBotSelection = (selectedBot) => {
		setSelectedBot(selectedBot);
		if (chartType === "Multiplicadores") {
			switch (selectedBot) {
				case "Logaritmic":
					runLogaritmic(tvWidget);
					break;
				case "TwinStrike":
					console.log("TWINSTRIKE SELECTED");
					runtwinsStrike(tvWidget);
					onClose(true);
					break;
				case "ProfitBoost":
					// Handle ProfitBoost bot selection
					break;
				default:
					break;
			}
		} else if (chartType === "Opciones") {
			switch (selectedBot) {
				case "Accumulators":
					runAccumulators(tvWidget, epochs, optionType === "accumulators" ? predictionTicks : ticks, windowSize);
					onClose(true);
					break;
				case "Touch No Touch":
					runTouchNoTouch(tvWidget, epochs, ticks, windowSize);
					onClose(true);
					break;
				case "Higher Lower":
					runHigherLower(tvWidget, epochs, ticks, windowSize);
					onClose(true);
					break;
				case "Rise Fall":
					runRiseFall(tvWidget, epochs, ticks, windowSize);
					onClose(true);
					break;
				default:
					break;
			}
		}
	};

	const closeModal = () => {
		setSelectedBot(null);
		onClose(false);
	};

	return (
		<Modal open={open} onClose={closeModal}>
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: "75%",
					height: "75%",
					bgcolor: "background.paper",
					boxShadow: 24,
					p: 4,
					display: "flex",
					borderRadius: "30px",
					boxShadow: "0 0 10px #4599d9, 0 0 40px #4599d9",
				}}>
				<Typography
					variant="h4"
					sx={{
						color: "#fff",
						fontFamily: "'Orbitron', sans-serif",
						fontSize: "2rem",
						fontWeight: 700,
						textTransform: "uppercase",
						letterSpacing: "2px",
						position: "absolute",
						right: "35%",
					}}>
					Estrategias
				</Typography>
				<Box
					sx={{
						width: "45%",
						mr: 2,
						backgroundColor: "transparent",
						overflow: "auto",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}>
					<List sx={{ padding: 0 }}>
						{Object.keys(botDetails).map((bot) => (
							<ListItem
								key={bot}
								sx={{
									mb: 4,
									borderRadius: 2,
									backgroundColor: selectedBot === bot ? "#f74712" : "#2a2e39",
									boxShadow: selectedBot === bot ? "0 0 10px #f74712, 0 0 30px #f74712" : "0 0 5px #f74712, 0 0 15px #f74712",
									transition: "all 0.3s ease",
									"&:hover": {
										transform: "scale(1.05)",
										boxShadow: "0 0 10px #f74712, 0 0 30px #f74712",
									},
								}}>
								<ListItemButton onClick={() => setSelectedBot(bot)}>
									<ListItemText
										primary={bot}
										sx={{
											color: "#fff",
											textAlign: "center",
											"& .MuiListItemText-primary": {
												fontFamily: "'Orbitron', sans-serif",
												fontSize: "1.5rem",
												fontWeight: 700,
												textTransform: "uppercase",
												letterSpacing: "2px",
											},
										}}
									/>
								</ListItemButton>
							</ListItem>
						))}
					</List>{" "}
				</Box>
				<Box
					sx={{
						width: "70%",
						backgroundColor: "tranparent",
						padding: 3,
						overflow: "auto",
						pt: 10,
					}}>
					{selectedBot && (
						<>
							<Grid container>
								<Grid item xs={12}>
									<Box display="flex" justifyContent="center" width="100%">
										<img
											src={botDetails[selectedBot].images[0]}
											alt={`${selectedBot} image`}
											style={{
												width: "95%",
												borderRadius: 8,
												boxShadow: "0 0 5px #4599d9, 0 0 20px #4599d9",
											}}
										/>
									</Box>
								</Grid>
								<Grid item xs={12}>
									<Typography
										variant="body1"
										sx={{
											color: "#fff",
											textAlign: "center",
											fontFamily: "'Orbitron', sans-serif",
											fontSize: "1rem",
											fontWeight: 500,
											letterSpacing: "1px",
											mt: 2,
										}}>
										{botDetails[selectedBot].details}
									</Typography>
								</Grid>
								{chartType === "Opciones" && (
									<Box sx={{ display: "flex", flexDirection: "row", gap: 2, marginBottom: 2, mt: 2 }}>
										<TextField
											label="Window Size"
											type="number"
											value={windowSize}
											onChange={(e) => setWindowSize(Number(e.target.value))}
											variant="outlined"
											size="small"
										/>
										<TextField
											label="Predecir Ticks"
											type="number"
											value={predictionTicks}
											onChange={(e) => setPredictionTicks(Number(e.target.value))}
											variant="outlined"
											size="small"
											disabled={optionType === "accumulators" ? false : true}
										/>
										{optionType !== "accumulators" && (
											<TextField
												label="Barrera"
												type="text"
												value={barrier}
												onChange={async (e) => {
													setBarrier(Number(e.target.value));
													await localStorage.setItem("barrier", e, target.value);
												}}
												variant="outlined"
												size="small"
												disabled={true}
											/>
										)}
										<TextField
											label="Epochs"
											type="number"
											value={epochs}
											onChange={(e) => setEpochs(Number(e.target.value))}
											variant="outlined"
											size="small"
										/>
									</Box>
								)}
								<Box display="flex" justifyContent="center" width="100%">
									<Button
										variant="contained"
										onClick={() => handleBotSelection(selectedBot)}
										sx={{
											mt: 2,
											color: "#fff",
											fontFamily: "'Orbitron', sans-serif",
											fontSize: "1.2rem",
											fontWeight: 700,
											textTransform: "uppercase",
											letterSpacing: "1px",
											backgroundColor: "#f74712",
											boxShadow: "0 0 5px #f74712, 0 0 15px #f74712",
											padding: "12px 24px",
											"&:hover": {
												backgroundColor: "#d93d0d",
											},
										}}>
										Activar
									</Button>
									{/* <Button
										variant="contained"
										onClick={() => {
											toast.dark("Esta opción estará disponible próximamente", {
												position: "top-right",
												autoClose: 1000,
												hideProgressBar: false,
												closeOnClick: true,
												pauseOnHover: true,
												draggable: true,
												theme: "dark",
												progressStyle: { background: "#f74712" },
											});
										}}
										sx={{
											ml: 5,
											mt: 2,
											color: "#fff",
											fontFamily: "'Orbitron', sans-serif",
											fontSize: "1.2rem",
											fontWeight: 700,
											textTransform: "uppercase",
											letterSpacing: "1px",
											backgroundColor: "#f74712",
											boxShadow: "0 0 5px #f74712, 0 0 15px #f74712",
											padding: "12px 24px",
											"&:hover": {
												backgroundColor: "#d93d0d",
											},
										}}>
										Activar
									</Button> */}
								</Box>
							</Grid>
						</>
					)}
				</Box>
			</Box>
		</Modal>
	);
};

export default BotsPage;
