import { create, all } from "mathjs";
import * as tf from "@tensorflow/tfjs";
import { store } from "../../../../store/store";
import { toast } from "react-toastify";
import { getLastCloses, getShocks } from "../../streaming.js";

const config = {};
const math = create(all, config);

// Parámetros configurables
const ticksDataLimit = 5000;

export const runAccumulators = (tvWidget, epochs, predictionTicks, windowSize) => {
	let prices = [];
	let shockData = [];
	let volatilidades = [];
	let model;
	let previousTick = 0;
	let mean = [];
	let std = [];
	let validActualTick = 0;
	let predictedVolatilities = [];
	let meanShock = null;
	let medianShock = null;
	let stdDevShock = null;
	let circleShape = null;
	let lastProcessedTick = -1;
	let barrierDistance = localStorage.getItem("barrierDistance");

	const showToast = (message) => {
		toast.dark(message, {
			position: "bottom-right",
			autoClose: 3000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			theme: "dark",
		});
	};

	async function init() {
		prices = await getLastCloses("1T", ticksDataLimit);
		shockData = await getShocks();
		if (prices.length > 0 && shockData.length > 0) {
			volatilidades = calcularVolatilidadesIniciales(prices, windowSize);
			await processData();
			analyzeShockData();
			startTickCollection();
		} else {
			showToast("No se pudieron obtener los datos iniciales.");
		}
	}

	const analyzeShockData = () => {
		meanShock = math.mean(shockData);
		medianShock = math.median(shockData);
		stdDevShock = math.std(shockData);
		const intervals = analyzeShockIntervals(shockData, 7);
	};

	function analyzeShockIntervals(shockData, threshold) {
		const nonCollisionIntervals = [];
		let currentInterval = [];

		shockData.forEach((ticksSinceLastCollision, index) => {
			if (ticksSinceLastCollision > threshold) {
				// Si el valor actual es mayor que el umbral, es parte de un intervalo sin choques
				currentInterval.push(index);
			} else {
				// Si el valor actual es menor o igual al umbral, termina el intervalo actual
				if (currentInterval.length > 0) {
					nonCollisionIntervals.push([...currentInterval]);
					currentInterval = [];
				}
			}
		});

		// Añadir el último intervalo si no se ha cerrado
		if (currentInterval.length > 0) {
			nonCollisionIntervals.push([...currentInterval]);
		}

		// Analizar los intervalos sin choques para determinar entradas y salidas
		nonCollisionIntervals.forEach((interval) => {
			const intervalLength = interval.length;
			if (intervalLength >= threshold) {
				const entryPoint = interval[0];
				const exitPoint = interval[intervalLength - 1];
			}
		});
	}

	function calcularVolatilidadesIniciales(prices, windowSize) {
		const volatilidadesIniciales = [];

		for (let i = windowSize; i < prices.length; i++) {
			const windowPrices = prices.slice(i - windowSize, i);
			const stdDev = calcularVolatilidad(windowPrices);
			volatilidadesIniciales.push(stdDev);
		}

		return volatilidadesIniciales;
	}

	function calcularVolatilidad(windowPrices) {
		const returns = [];
		for (let i = 1; i < windowPrices.length; i++) {
			const prevPrice = windowPrices[i - 1];
			const currentPrice = windowPrices[i];
			if (prevPrice === 0) {
				returns.push(0);
			} else {
				returns.push(Math.log(currentPrice / prevPrice));
			}
		}

		const stdDev = math.std(returns);
		return isNaN(stdDev) ? 0 : stdDev;
	}

	function calculateRSI(prices, period = 5) {
		let gains = [];
		let losses = [];

		for (let i = 1; i < prices.length; i++) {
			const difference = prices[i] - prices[i - 1];
			if (difference >= 0) {
				gains.push(difference);
				losses.push(0);
			} else {
				losses.push(Math.abs(difference));
				gains.push(0);
			}
		}
		const averageGain = math.mean(gains.slice(-period));
		const averageLoss = math.mean(losses.slice(-period));

		if (averageLoss === 0) return 100;
		const relativeStrength = averageGain / averageLoss;
		const rsi = 100 - 100 / (1 + relativeStrength);

		return isNaN(rsi) ? 50 : rsi;
	}

	function calculateMACD(prices, fastPeriod = 4, slowPeriod = 7, signalPeriod = 3) {
		const emaFast = calculateEMA(prices, fastPeriod);
		const emaSlow = calculateEMA(prices, slowPeriod);
		const macdLine = emaFast - emaSlow;
		const signalLine = calculateEMA([macdLine], signalPeriod);
		return { macdLine, signalLine };
	}

	function calculateMomentum(prices, period = 5) {
		if (prices.length < period) return null;
		return prices[prices.length - 1] - prices[prices.length - period];
	}

	function calculateEMA(prices, period) {
		const multiplier = 2 / (period + 1);
		let ema = prices[0];
		for (let i = 1; i < prices.length; i++) {
			ema = (prices[i] - ema) * multiplier + ema;
		}
		return ema;
	}

	const startTickCollection = () => {
		setInterval(async () => {
			try {
				if (!model) {
					console.error("Model is not defined Interval.");
					return;
				}
				const currentPrice = Number(store.getState().lastBar.close);
				const actualTick = Number(store.getState().tickCount);
				const time = Number(store.getState().lastBar.epoch);

				if (actualTick === lastProcessedTick) {
					return;
				}
				lastProcessedTick = actualTick;
				validActualTick = isNaN(actualTick) ? 0 : actualTick;

				if (!isNaN(currentPrice)) {
					prices.push(currentPrice);
					if (prices.length > ticksDataLimit) {
						prices.shift();
					}

					const recentWindowPrices = prices.slice(-windowSize);
					const currentVolatility = calcularVolatilidad(recentWindowPrices);
					volatilidades.push(currentVolatility);
					if (volatilidades.length > ticksDataLimit) {
						volatilidades.shift();
					}

					if (validActualTick === 0) {
						shockData.push(previousTick);

						tvWidget.activeChart().createMultipointShape(
							[
								{ time: time, price: currentPrice },
								{ time: time - 3, price: currentPrice },
							],
							{
								shape: "circle",
								text: `${previousTick}`,
								overrides: {
									backgroundColor: "rgba(255, 0, 0, 0.5)",
									color: "red",
									borderWidth: 2,
									textColor: "white",
									fontSize: 12,
									fontBold: true,
									zIndex: 100,
								},
								zOrder: "top",
							}
						);

						analyzeShockData();
					}

					previousTick = validActualTick;

					if (prices.length >= windowSize + predictionTicks && volatilidades.length >= windowSize + predictionTicks) {
						const recentPrices = prices.slice(-windowSize);
						const rsi = calculateRSI(recentPrices);
						const macd = calculateMACD(recentPrices);
						const momentum = calculateMomentum(recentPrices);

						const input = [...recentPrices, rsi, macd.macdLine, macd.signalLine, momentum];
						const normalizedInput = normalizeSingleInput(input, mean, std);

						if (normalizedInput.some((val) => isNaN(val))) {
							console.error("La entrada normalizada contiene NaN. No se puede realizar la predicción.");
						} else {
							const predictions = predictVolatility(model, normalizedInput);
							predictedVolatilities.push(predictions.volatility);
							if (predictedVolatilities.length > 300) {
								predictedVolatilities.shift();
							}
							const avgPredictedVolatility = math.mean(predictedVolatilities);
							const maxPredictedVolatility = Math.max(...predictedVolatilities);
							const minPredictedVolatility = Math.min(...predictedVolatilities);

							updatePredictionDisplay(
								predictionTicks,
								predictions.volatility,
								avgPredictedVolatility,
								maxPredictedVolatility,
								minPredictedVolatility,
								predictions.barrierUpProb,
								predictions.barrierDownProb,
								rsi
							);

							if (circleShape) {
								tvWidget.activeChart().removeEntity(circleShape);
							}

							circleShape = tvWidget.activeChart().createMultipointShape(
								[
									{ time: time, price: currentPrice },
									{ time: time + 3, price: currentPrice },
								],
								{
									shape: "circle",
									text: `${validActualTick}`,
									overrides: {
										backgroundColor: "rgba(0, 100, 255, 0.3)",
										color: "blue",
										borderWidth: 2,
										textColor: "white",
										fontSize: 14,
										bold: true,
										zIndex: 100,
									},
									zOrder: "top",
								}
							);
						}
					} else {
						console.log("No hay suficientes datos para realizar la predicción.");
					}
				} else {
					console.log("currentPrice es NaN. Esperando datos válidos.");
				}
			} catch (error) {
				console.error("Error en startTickCollection:", error);
			}
		}, 1000);
	};

	async function processData() {
		showToast("Procesando datos para el entrenamiento...");
		const { inputs, labels } = createDataset(prices, volatilidades, windowSize, Number(predictionTicks));
		if (inputs.length === 0) {
			showToast("No hay suficientes datos para crear el conjunto de entrenamiento.");
			return;
		}

		const normalizedInputs = normalizeDataTraining(inputs);

		const hasNaNInInputs = normalizedInputs.some((row) => row.some((val) => isNaN(val)));
		const hasNaNInLabels = labels.some((val) => isNaN(val));

		if (hasNaNInInputs || hasNaNInLabels) {
			console.error("Los datos normalizados contienen NaN. Abortando entrenamiento.");
			return;
		}

		showToast("Creando y entrenando el modelo...");
		model = createModel(windowSize);
		await trainModel(model, normalizedInputs, labels, epochs);
		showToast("Modelo entrenado y listo para predicciones.");
	}

	function createDataset(prices, volatilidades, windowSize, predictionTicks) {
		console.log("Creando conjunto de datos...", predictionTicks);
		const inputs = [];
		const labels = [];

		for (let i = 0; i < prices.length - windowSize - predictionTicks; i++) {
			const inputPrices = prices.slice(i, i + windowSize);
			const futureVolatility = volatilidades[i + windowSize + predictionTicks];

			const currentPrice = inputPrices[inputPrices.length - 1];
			const barrierUP = currentPrice + parseFloat(barrierDistance);
			const barrierDN = currentPrice - parseFloat(barrierDistance);
			const futurePrices = prices.slice(i + windowSize, i + windowSize + predictionTicks);
			const reachedBarrierUP = futurePrices.some((price) => price >= barrierUP) ? 1 : 0;
			const reachedBarrierDN = futurePrices.some((price) => price <= barrierDN) ? 1 : 0;

			if (inputPrices.includes(0) || isNaN(futureVolatility)) {
				continue;
			}

			// Calculate indicators
			const rsi = calculateRSI(inputPrices);
			const macd = calculateMACD(inputPrices);
			const momentum = calculateMomentum(inputPrices);

			// Include indicators in the input
			inputs.push([...inputPrices, rsi, macd.macdLine, macd.signalLine, momentum]);
			labels.push(futureVolatility, reachedBarrierUP, reachedBarrierDN);
		}

		return { inputs, labels: labels.flat() };
	}

	function validarDatos(inputs, labels) {
		let valid = true;

		inputs.forEach((input, index) => {
			if (input.some((val) => isNaN(val))) {
				console.error(`NaN encontrado en inputs en el índice ${index}:`, input);
				valid = false;
			}
			if (input.length !== windowSize) {
				console.error(`Longitud incorrecta en inputs en el índice ${index}. Esperado: ${windowSize}, Actual: ${input.length}`);
				valid = false;
			}
		});

		labels.forEach((label, index) => {
			if (isNaN(label)) {
				console.error(`NaN encontrado en labels en el índice ${index}:`, label);
				valid = false;
			}
		});

		return valid;
	}

	function normalizeDataTraining(data) {
		const dataT = math.transpose(data);
		const normalizedDataT = dataT.map((col, idx) => {
			const m = math.mean(col);
			const s = math.std(col);
			mean.push(m);
			std.push(s);
			if (s === 0 || isNaN(s)) {
				return col.map(() => 0);
			}
			return col.map((val) => (val - m) / s);
		});
		return math.transpose(normalizedDataT);
	}

	function normalizeSingleInput(input, mean, std) {
		return input.map((val, idx) => {
			if (std[idx] === 0 || isNaN(std[idx])) {
				return 0;
			}
			return (val - mean[idx]) / std[idx];
		});
	}

	function createModel(inputSize) {
		const model = tf.sequential();
		model.add(
			tf.layers.dense({
				inputShape: [inputSize + 4],
				units: 64,
				activation: "relu",
			})
		);
		model.add(tf.layers.dense({ units: 32, activation: "relu" }));
		model.add(tf.layers.dense({ units: 3, activation: "linear" }));

		model.compile({
			optimizer: "adam",
			loss: "meanSquaredError",
			metrics: ["mae"],
		});

		return model;
	}

	async function trainModel(model, inputs, labels, epochs) {
		const numSamples = Math.min(inputs.length, Math.floor(labels.length / 3));

		const inputTensor = tf.tensor2d(inputs.slice(0, numSamples));
		const labelTensor = tf.tensor2d(labels.slice(0, numSamples * 3), [numSamples, 3]);

		const hasNaNInInput = tf.isNaN(inputTensor).any().dataSync()[0];
		const hasNaNInLabel = tf.isNaN(labelTensor).any().dataSync()[0];

		if (hasNaNInInput || hasNaNInLabel) {
			console.error("Los tensores contienen NaN. Abortando entrenamiento.");
			return;
		}

		await model.fit(inputTensor, labelTensor, {
			batchSize: 32,
			epochs,
			validationSplit: 0.2,
			callbacks: {
				onEpochEnd: (epoch, logs) => {
					showToast(`Epoch ${epoch + 1}/${epochs} - loss: ${logs.loss.toFixed(6)} - mae: ${logs.mae.toFixed(6)}`);
				},
				onTrainEnd: () => {
					showToast("Entrenamiento completado.");
				},
			},
		});
	}

	function predictVolatility(model, recentInput) {
		if (!model) {
			console.error("Model is not defined.");
			return { volatility: NaN, barrierUpProb: NaN, barrierDownProb: NaN };
		}

		const inputTensor = tf.tensor2d([recentInput]);
		const hasNaNInInput = tf.isNaN(inputTensor).any().dataSync()[0];

		if (hasNaNInInput) {
			console.error("El tensor de entrada contiene NaN. No se puede realizar la predicción.");
			return { volatility: NaN, barrierUpProb: NaN, barrierDownProb: NaN };
		}

		const prediction = model.predict(inputTensor);
		const predictions = prediction.arraySync()[0];
		console.log("Prediction:", predictions);

		return {
			volatility: predictions[0],
			barrierUpProb: predictions[1],
			barrierDownProb: predictions[2],
		};
	}

	init();
};
function updatePredictionDisplay(
	predictionTicks,
	predictedVolatility,
	avgPredictedVolatility,
	maxPredictedVolatility,
	minPredictedVolatility,
	barrierUpProb,
	barrierDownProb,
	rsi
) {
	const predictionBox = document.getElementById("predictionBox");
	if (!predictionBox) {
		const box = document.createElement("div");
		box.id = "predictionBox";
		box.style.cssText = `
            position: absolute;
            bottom: 0%;
            left: 10%;
            transform: translate(-50%, -50%);
            background-color: rgba(23, 27, 38, 0.9);
            padding: 8px;
            border-radius: 8px;
            color: white;
            box-shadow: 0 0 5px #4599d9, 0 0 10px #4599d9;
            font-family: 'Orbitron', sans-serif;
            font-size: 12px;
            line-height: 1.2;
            cursor: move;
            user-select: none;
        `;
		document.body.appendChild(box);
		makeDraggable(box);
	}

	document.getElementById("predictionBox").innerHTML = `
        <p>Prediction Ticks: <span style="float: right;">${predictionTicks}</span></p>
        <p>Current Volatility: <span style="float: right; color: ${
			predictedVolatility < avgPredictedVolatility ? "green" : "orange"
		}">${predictedVolatility.toFixed(6)}</span></p>
        <p>RSI: <span style="float: right; color: ${rsi > 65 ? "red" : rsi < 45 ? "red" : "green"}">${rsi.toFixed(2)}</span></p>
		 <p>Barrier UP Touch: <span style="float: right; color: ${barrierUpProb > 0.25 ? "orange" : "green"}">${(barrierUpProb * 100).toFixed(2)}%</span></p>
        <p>Barrier DOWN Touch: <span style="float: right; color: ${barrierDownProb > 0.25 ? "orange" : "green"}">${(barrierDownProb * 100).toFixed(
		2
	)}%</span></p>
		<hr style="border: 1px solid #4599d9; margin: 8px 0;">
 
        <p>Max Volatility: <span style="float: right; color: red;">${maxPredictedVolatility.toFixed(6)}</span></p>
        <p>Avg Volatility: <span style="float: right; color: orange;">${avgPredictedVolatility.toFixed(6)}</span></p>
        <p>Min Volatility: <span style="float: right; color: green;">${minPredictedVolatility.toFixed(6)}</span></p>
       
    `;
}

function makeDraggable(element) {
	let pos1 = 0,
		pos2 = 0,
		pos3 = 0,
		pos4 = 0;
	element.onmousedown = dragMouseDown;

	function dragMouseDown(e) {
		e = e || window.event;
		e.preventDefault();
		pos3 = e.clientX;
		pos4 = e.clientY;
		document.onmouseup = closeDragElement;
		document.onmousemove = elementDrag;
	}

	function elementDrag(e) {
		e = e || window.event;
		e.preventDefault();
		pos1 = pos3 - e.clientX;
		pos2 = pos4 - e.clientY;
		pos3 = e.clientX;
		pos4 = e.clientY;
		element.style.top = element.offsetTop - pos2 + "px";
		element.style.left = element.offsetLeft - pos1 + "px";
	}

	function closeDragElement() {
		document.onmouseup = null;
		document.onmousemove = null;
	}
}
