import React, { useEffect, useState, useRef, useCallback } from "react";
import { Box, CircularProgress, Typography, TextField } from "@mui/material";
import { store } from "../../../../store/store.js";

export function WithdrawalsTable() {
	const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
	const derivWS = store((state) => state.derivWS);
	const [rows, setRows] = useState([]);
	const [loading, setLoading] = useState(true);
	const [totals, setTotals] = useState({ totalDeposits: 0, totalWithdrawals: 0, balance: 0 });
	const [offset, setOffset] = useState(0);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const containerRef = useRef(null);

	useEffect(() => {
		findHistoryPositions({ startDate, endDate });
	}, []);

	const findHistoryPositions = async ({ startDate, endDate }) => {
		let epochStartDate, epochEndDate;

		if (!startDate) {
			const sevenDaysAgo = new Date();
			sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
			epochStartDate = Math.round(sevenDaysAgo.getTime() / 1000);
		} else {
			epochStartDate = Math.round(new Date(startDate).getTime() / 1000);
		}

		if (!endDate) {
			epochEndDate = Math.round(Date.now() / 1000);
		} else {
			epochEndDate = Math.round(new Date(endDate).getTime() / 1000);
		}

		epochStartDate = Math.max(0, Math.min(epochStartDate, 9999999999));
		epochEndDate = Math.max(0, Math.min(epochEndDate, 9999999999));

		setStartDate(startDate || new Date(epochStartDate * 1000).toISOString().split("T")[0]);
		setEndDate(endDate || new Date(epochEndDate * 1000).toISOString().split("T")[0]);
		setLoading(true);
		setRows([]); // Clear existing rows before new requests

		try {
			const processedTransactions = new Set();
			let allTransactions = [];

			const handleTransactions = (transactions) => {
				let totalDeposits = 0;
				let totalWithdrawals = 0;

				const newRows = transactions
					.filter((transaction) => !processedTransactions.has(transaction.transaction_id))
					.map((transaction) => {
						processedTransactions.add(transaction.transaction_id);

						const date = new Date(transaction.transaction_time * 1000);
						const formattedDate =
							date.toLocaleDateString("es-ES") +
							" " +
							date.toLocaleTimeString("es-ES", {
								hour: "2-digit",
								minute: "2-digit",
							});

						const amount = transaction.amount;
						const type = transaction.action_type;
						const status = transaction.status;
						const balance = transaction.balance_after;

						if (type === "deposit") {
							totalDeposits += amount;
						} else {
							totalWithdrawals += Math.abs(amount);
						}

						return {
							id: transaction.transaction_id,
							date: formattedDate,
							type: type.toUpperCase(),
							amount: Math.abs(amount).toFixed(2),
							status: status,
							balance: balance.toFixed(2),
							description: transaction.longcode,
						};
					});

				allTransactions = [...allTransactions, ...newRows];
				allTransactions.sort((a, b) => new Date(b.date) - new Date(a.date));

				setRows(allTransactions);

				setTotals((prevTotals) => ({
					totalDeposits: (parseFloat(prevTotals.totalDeposits) + totalDeposits).toFixed(2),
					totalWithdrawals: (parseFloat(prevTotals.totalWithdrawals) + totalWithdrawals).toFixed(2),
					balance: (
						parseFloat(prevTotals.totalDeposits) +
						totalDeposits -
						parseFloat(prevTotals.totalWithdrawals) -
						totalWithdrawals
					).toFixed(2),
				}));

				setLoading(false);
			};

			// Request deposits
			const datesDeposit = {
				statement: 1,
				description: 1,
				limit: 500,
				date_from: epochStartDate,
				date_to: epochEndDate,
				action_type: "deposit",
			};
			derivWS.send(JSON.stringify(datesDeposit));

			// Request withdrawals
			const datesWithdrawal = {
				statement: 1,
				description: 1,
				limit: 500,
				date_from: epochStartDate,
				date_to: epochEndDate,
				action_type: "withdrawal",
			};
			derivWS.send(JSON.stringify(datesWithdrawal));

			derivWS.onmessage = function (msg) {
				const msgData = JSON.parse(msg.data);
				const msgType = msgData.msg_type;

				if (msgType === "statement") {
					const transactions = msgData.statement?.transactions;
					if (transactions && transactions.length > 0) {
						handleTransactions(transactions);
					}
				}
			};
		} catch (error) {
			console.error("Error loading transactions:", error);
			setLoading(false);
		}
	};

	const handleScroll = useCallback(() => {
		if (containerRef.current) {
			const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
			if (scrollTop + clientHeight >= scrollHeight) {
				setOffset((prevOffset) => prevOffset + 25);
			}
		}
	}, []);

	useEffect(() => {
		if (containerRef.current) {
			containerRef.current.addEventListener("scroll", handleScroll);
			return () => containerRef.current.removeEventListener("scroll", handleScroll);
		}
	}, [handleScroll]);

	if (loading && rows.length === 0) {
		return (
			<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
				<CircularProgress />
			</Box>
		);
	}

	return (
		<Box sx={{ width: "100%", backgroundColor: "#161a25", padding: 1, position: "relative" }}>
			<Box sx={{ position: "absolute", top: 8, right: 16, zIndex: 9999, display: "flex", gap: 2 }}>
				<TextField
					type="date"
					label="Fecha inicio"
					value={startDate || ""}
					onChange={(e) => findHistoryPositions({ startDate: e.target.value, endDate })}
					sx={{
						backgroundColor: "rgba(255, 255, 255, 0.2)",
						borderRadius: "8px",
						height: "30px",
						width: "100px",
						"& .MuiInputBase-input": {
							fontSize: "12px",
							padding: "4px 8px",
						},
						"& .MuiInputLabel-root": {
							fontSize: "12px",
						},
						"& .MuiSvgIcon-root": {
							color: "white",
						},
					}}
					InputLabelProps={{
						shrink: true,
					}}
					InputProps={{
						style: {
							height: "30px",
						},
					}}
				/>
				<TextField
					type="date"
					label="Fecha fin"
					value={endDate || ""}
					onChange={(e) => findHistoryPositions({ startDate, endDate: e.target.value })}
					sx={{
						backgroundColor: "rgba(255, 255, 255, 0.2)",
						borderRadius: "8px",
						height: "30px",
						width: "100px",
						"& .MuiInputBase-input": {
							fontSize: "12px",
							padding: "4px 8px",
						},
						"& .MuiInputLabel-root": {
							fontSize: "12px",
						},
						"& .MuiSvgIcon-root": {
							color: "white",
						},
					}}
					InputLabelProps={{
						shrink: true,
					}}
					InputProps={{
						style: {
							height: "30px",
						},
					}}
				/>
			</Box>

			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					backgroundColor: "rgba(69, 153, 217, 0.2)",
					padding: "8px 15px",
					borderRadius: "8px",
					color: "#eaeaea",
				}}>
				<Box sx={{ flex: 1 }}>Fecha</Box>
				<Box sx={{ flex: 1 }}>Tipo</Box>
				<Box sx={{ flex: 1 }}>Monto</Box>
				<Box sx={{ flex: 1 }}>Saldo</Box>
				<Box sx={{ flex: 1 }}>Descripción</Box>
			</Box>

			<Box
				ref={containerRef}
				sx={{
					maxHeight: "400px",
					overflowY: "auto",
					padding: "15px",
				}}>
				{rows.map((row) => (
					<Box
						key={row.id}
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							backgroundColor: "rgba(23, 27, 38, 0.9)",
							padding: "6px 15px",
							borderRadius: "8px",
							boxShadow: "0px 3px 5px rgba(69, 153, 217, 0.3)",
							color: "#fff",
							cursor: "pointer",
							marginBottom: "6px",
							transition: "background-color 0.3s ease",
							"&:hover": {
								backgroundColor: "rgba(69, 153, 217, 0.2)",
							},
						}}>
						<Box sx={{ flex: 1 }}>{row.date}</Box>
						<Box sx={{ flex: 1 }}>{row.type}</Box>
						<Box sx={{ flex: 1 }}>{row.amount}</Box>
						<Box sx={{ flex: 1 }}>{row.balance}</Box>
						<Box sx={{ flex: 1, fontSize: "10px", lineHeight: "1.2" }}>{row.description}</Box>
					</Box>
				))}
			</Box>

			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					backgroundColor: "rgba(113, 121, 126, 0.7)",
					padding: "10px 15px",
					borderRadius: "8px",
					marginTop: "16px",
					color: "#fff",
					fontWeight: "bold",
					position: "sticky",
					bottom: 0,
					zIndex: 1,
				}}>
				<Box sx={{ flex: 1, fontSize: 16, fontWeight: "bold" }}>Total Depósitos</Box>
				<Box sx={{ flex: 1, fontSize: 16, fontWeight: "bold" }} style={{ color: "#4599d9" }}>
					{totals.totalDeposits}
				</Box>
				<Box sx={{ flex: 1, fontSize: 16, fontWeight: "bold" }}>Total Retiros</Box>
				<Box sx={{ flex: 1, fontSize: 16, fontWeight: "bold" }} style={{ color: "#f74712" }}>
					{totals.totalWithdrawals}
				</Box>
				<Box sx={{ flex: 1, fontSize: 16, fontWeight: "bold" }}>Balance</Box>
				<Box sx={{ flex: 1, fontSize: 16, fontWeight: "bold", color: totals.balance >= 0 ? "#4599d9" : "#f74712" }}>{totals.balance}</Box>
			</Box>
		</Box>
	);
}
