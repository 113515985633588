import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { Box, Card, TextField, Button as MUIButton, Typography, Slider } from "@mui/material";
import { io } from "socket.io-client";
import { Rnd } from "react-rnd";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { store } from "../../../../../store/store.js";
import { toast } from "react-toastify";
import socket from "../../../../../socket/Socket.js";
import { derivWS, waitForMessageOfType, handleOpenOption, getShocks, getSymbols, removeAccumLines } from "../../../streaming.js";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";

const theme = createTheme({
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					minWidth: "0", // Override the default minWidth
				},
			},
		},
	},
	palette: {
		mode: "dark",
		primary: {
			main: "#35e737",
		},
		background: {
			default: "#121212",
			paper: "#1d1d1d",
		},
		text: {
			primary: "#ffffff",
		},
	},
});

const calculatePosition = ({ x }) => {
	return {
		x: x - 175,
		y: 50,
	};
};

let activeSL = true;
let activeTP = true;
let activeSLTP = false;
let orderPosition = false;

export const translations = {
	"Error:": "Error:",
	"Barrier Result:": "Resultado de la Barrera:",
	"Barrier is out of acceptable range.": "La barrera está fuera del rango aceptable.",
	"This contract offers no return.": "Este contrato no ofrece retorno.",
	"Please enter a stake amount that's at least 0.35.": "Ingrese una cantidad de apuesta de al menos 0.35.",
	"Number of ticks must be between 5 and 10.": "El número de ticks debe estar entre 5 y 10.",
	"Input validation failed: barrier": "Validación de entrada fallida: barrera",
	"Trading is not offered for this duration.": "El comercio no está disponible para esta duración.",
	"Missing required contract parameters (growth_rate).": "Faltan parámetros, selecciona un Tick",
};

export function MyDraggableComponent() {
	const chartType = localStorage.getItem("chartType");
	const tvWidget = store((state) => state.chart);
	const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
	const user = JSON.parse(localStorage.getItem("user"));
	const symbolInfo = store((state) => state.symbolInfo);
	const balance = store((state) => state.balance);

	const [position, setPosition] = useState(() => {
		const savedPosition = localStorage.getItem("windowPosition");
		return savedPosition ? JSON.parse(savedPosition) : calculatePosition({ x: window.innerWidth / 1.5 });
	});
	const [riskAmount, setRiskAmount] = useState(() => {
		return localStorage.getItem("riskAmount") || 1;
	});

	const [inputValue, setInputValue] = useState(() => {
		return localStorage.getItem("inputValue") || "";
	});

	const [retCandlesQty, setRetCandlesQty] = useState(() => {
		return localStorage.getItem("retCandlesQty") || 0.1;
	});
	const [tradeAmount, setTradeAmount] = useState(1);
	const [slTpButtonColor, setSlTpButtonColor] = useState("#808080");
	const [slTpButton, setSlTpButton] = useState(false);
	const [retCandles, setRetCandles] = useState(false);
	const [derivMultiplier, setDerivMultiplier] = useState();
	const [preStopLoss, setPreStopLoss] = useState(null);
	const [trailingStop, setTrailingStop] = useState(false);
	const [positionType, setPositionType] = useState("multipliers");
	const [contractType, setContractType] = useState();
	const [sliderValue, setSliderValue] = useState(0);
	const [barrier, setBarrier] = useState("0.14");
	const [profitTick, setProfitTick] = useState(5);
	const [callPayout, setCallPayout] = useState();
	const [putPayout, setPutPayout] = useState();
	const [dragging, setDragging] = useState(false);
	const [isLocked, setIsLocked] = useState(false);

	let lastBarRef = useRef(null);
	const trailingIntervalRef = useRef(null);
	const lastTrailingStopPriceRef = useRef({});
	const trailingDistanceRef = useRef(null);
	const tradeAmountRef = useRef(null);
	const derivMultiplierRef = useRef(null);
	const multiplierRef = useRef(null);
	const newPipValueRef = useRef(null);
	const riskAmountRef = useRef(null);
	const slRetCandlesRef = useRef(null);
	const tpRetCandlesRef = useRef(null);
	const slLineRef = useRef(null);
	const tpLineRef = useRef(null);
	const trailingLineRef = useRef(null);
	const SL = useRef(null);
	const TP = useRef(null);
	const tpProfit = useRef(null);
	const slProfit = useRef(null);
	const pipsSL = useRef(null);
	const pipsTP = useRef(null);
	const slOrderLineRef = useRef(null);
	const tpOrderLineRef = useRef(null);
	const SLOrder = useRef(null);
	const TPOrder = useRef(null);
	const pipsOrderSL = useRef(null);
	const pipsOrderTP = useRef(null);
	const orderRef = useRef(null);
	const existingLinesRef = useRef({});
	const existingOrdersRef = useRef({});
	const symbolInfoRef = useRef(symbolInfo);
	const tvWidgetRef = useRef(tvWidget);
	const barrierLineRef = useRef(null);
	const barrierIntervalRef = useRef(null);
	const barrierRef = useRef(null);
	const sliderRef = useRef(null);
	const callPayoutRef = useRef(null);
	const putPayoutRef = useRef(null);
	const contractTypeRef = useRef(contractType);
	const actualBalanceRef = useRef(null);
	const calculatedValueRef = useRef(null);
	const accumBodyRef = useRef(null);

	//==============================================// INITIAL LOTS RND POSITION
	useEffect(() => {
		const serRiskStuff = async () => {
			const iv = await localStorage.getItem("inputValue");
			const rcq = await localStorage.getItem("retCandleQty");
			calculateAmount(iv ? Number(iv) : 1);
			setInputValue(iv ? Number(iv) : 1);
			setRetCandlesQty(rcq ? Number(rcq) : 1);
		};
		if (balance !== null && balance > 0) {
			serRiskStuff();
		}
		const handleResize = () => {
			const newWidth = window.innerWidth;
			setPosition(calculatePosition({ x: newWidth / 2 }));
		};
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [balance]);

	//==============================================// GET LAST BAR
	const getLastBarValue = useCallback(() => {
		return store.getState().lastBar;
	}, []);

	useEffect(() => {
		if (!selectedAccount || !symbolInfo) return;
		const interval = setInterval(() => {
			const lastBarValue = getLastBarValue();
			lastBarRef.current = lastBarValue;
		}, 1000);

		return () => {
			clearInterval(interval);
		};
	}, [getLastBarValue, symbolInfo]);

	//==============================================// LOCAL STORAGE
	useEffect(() => {
		const chargeLocal = async () => {
			const barrier = await localStorage.getItem("barrier");
			const storageSLider = localStorage.getItem("sliderValue");
			const profitTick = localStorage.getItem("profitTick");

			setBarrier(barrier !== null ? barrier : "+0.150");
			setSliderValue(Number(storageSLider) || 5);
			setProfitTick(profitTick !== null ? profitTick : "5");

			barrierRef.current = barrier !== null ? barrier : "+0.150";
			sliderRef.current = storageSLider !== null ? storageSLider : "5";
		};
		chargeLocal();

		return () => {
			stopTrailingStopInterval();
		};
	}, []);

	useEffect(() => {
		const test = async () => {
			let chartType = localStorage.getItem("chartType");
			chartType === null ? localStorage.setItem("chartType", "Multiplicadores") : chartType;

			if (chartType === "Opciones") {
				if (contractTypeRef.current === "higherLower" || contractTypeRef.current === "touchNoTouch") {
					startBarrierInterval();
				}
			}
		};
		test();

		if (chartType === "Multiplicadores") {
			stopBarrierInterval();
		}
	}, [chartType, contractTypeRef.current]);

	//==============================================// SYMBOLINFO REF - GET SHOCKS
	useEffect(() => {
		async function call() {
			if (!symbolInfo) return;
			symbolInfoRef.current = symbolInfo;
			derivMultiplierRef.current = symbolInfo.derivMultiplier;

			const optionType = await localStorage.getItem("optionType");
			contractTypeRef.current = optionType;
			setContractType(optionType);

			if (chartType === "Opciones" && optionType === "accumulators") {
				getShocks();
			}

			// await updateBarrier(barrierRef.current);
			// await handleContractType("riseFall");
		}
		call();
	}, [symbolInfo]);

	//==============================================// SOCKET ON
	useEffect(() => {
		const handleAllLines = (data) => {
			console.log("ALL LINES", data);
			try {
				store.getState().setOpenPositions(data);
				const tvWidget = store.getState().chart;
				const symbolInfo = store.getState().symbolInfo;
				if (symbolInfo === null || tvWidget === null) {
					setTimeout(() => {
						handleAllLines(data);
					}, 500);
					return;
				}

				Object.keys(existingLinesRef.current).forEach((key) => {
					if (existingLinesRef.current[key].remove) {
						existingLinesRef.current[key].remove();
					}
					delete existingLinesRef.current[key];
				});

				if (trailingIntervalRef.current) {
					clearInterval(trailingIntervalRef.current);
					trailingIntervalRef.current = null;
				}
				if (trailingLineRef.current) {
					trailingLineRef.current.remove();
					trailingLineRef.current = null;
				}

				if (data === null) return;

				data.forEach((position) => {
					if (position.symbol === symbolInfo.symbol) {
						const positionKey = `position_${position.contractId}`;

						existingLinesRef.current[positionKey] = tvWidget
							.chart()
							.createPositionLine()
							.setText(
								position.orderPosition
									? `Posicion ${position.type}`
									: `Posicion ${position.type}  Multiplicador: ${position.derivMultiplier}`
							)
							.setQuantity(Number(position.tradeAmount).toFixed(2))
							.setPrice(position.orderPosition ? Number(position.orderPrice) : Number(position.entryPrice))
							.setLineColor("#4599d9")
							.setQuantityBorderColor("#4599d9")
							.setBodyTextColor("#a9a9a9")
							.setQuantityTextColor("#a9a9a9")
							.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
							.setQuantityBackgroundColor("rgba(0, 0, 0, 0)")
							.setCloseButtonBackgroundColor("rgba(0, 0, 0, 0)")
							.setCloseButtonIconColor("#a9a9a9")
							.setCloseButtonBorderColor("#4599d9")
							.setBodyBorderColor("#4599d9")
							.setTooltip(position.contractId)
							.setProtectTooltip(position.type.startsWith("Orden") ? "Orden" : "Activar SL/TP")
							.onClose("onClose called", function (text) {
								console.log("CLOSE", position);
								socket.emit("sellContract", position);
							})
							.onModify(function () {
								if (position.type.startsWith("Orden")) {
									console.log("NO ES ORDEN");
								} else {
									const contractId = this.getTooltip();
									const position = data.find(
										(p) => Number(p.contractId) === Number(contractId) && p.symbol === symbolInfoRef.current.symbol
									);
									const posKey = `position_${position.contractId}`;

									console.log("CLICK", contractId, position);
									if (position) {
										if (!existingLinesRef.current[`takeProfit_${contractId}`]) {
											existingLinesRef.current[posKey].setQuantityBackgroundColor("#4599d9");
											createSLTPLine(Number(contractId), "takeProfit", position);
										}
										if (!existingLinesRef.current[`stopLoss_${contractId}`]) {
											existingLinesRef.current[posKey].setQuantityBackgroundColor("#4599d9");
											createSLTPLine(Number(contractId), "stopLoss", position);
										}
									}
								}
							});

						if (position.stopLoss !== null) {
							createSLTPLine(Number(position.contractId), "stopLoss", position);
						}
						if (position.takeProfit !== null) {
							createSLTPLine(Number(position.contractId), "takeProfit", position);
						}

						if (position.trailingStop) {
							startTrailingStopInterval0(true, position);
						}
					}
				});

				// console.log("AFTER PROCESIONG", existingLinesRef.current);
			} catch (error) {
				console.log("ERROR ALL LINES", error);
			}
		};

		const createSLTPLine = (contractId, type, position) => {
			const slKey = `stopLoss_${contractId}`;
			const tpKey = `takeProfit_${contractId}`;
			const line = tvWidgetRef.current.chart().createOrderLine();

			if (type === "stopLoss") {
				existingLinesRef.current[slKey] = line
					.setText(
						position.preStopLoss
							? `STOP OUT: $${position.slProfit} -- Pips: ${position.pipsSL}`
							: position.orderPosition
							? `Order SL: $${position.slProfit} -- Pips: ${position.pipsSL}`
							: `SL: $${position.slProfit} -- Pips: ${position.pipsSL}`
					)
					.setPrice(
						position.stopLoss
							? position.stopLoss
							: position.type === "Buy"
							? position.entryPrice - 10 * position.pip
							: position.entryPrice + 10 * position.pip
					)
					.setQuantity(Number(position.tradeAmount).toFixed(2))
					.setLineColor("#f74712")
					.setQuantityBorderColor("#f74712")
					.setBodyTextColor("#f74712")
					.setBodyBorderColor("#f74712")
					.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
					.setQuantityBackgroundColor("rgba(0, 0, 0, 0)")
					.setCancelButtonBackgroundColor("rgba(0, 0, 0, 0)")
					.setCancelButtonIconColor("#f74712")
					.setCancelButtonBorderColor("#f74712")
					.setTooltip(contractId)
					.onMove(function () {
						if (Number(this.getTooltip()) === Number(position.contractId)) {
							existingLinesRef.current[`position_${contractId}`].setQuantityBackgroundColor("rgba(0, 0, 0, 0)");
							const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
							const currentPrice = this.getPrice();
							const entryPrice = position.orderPosition ? Number(position.orderPrice) : Number(position.entryPrice);
							const tradeAmount = Number(position.tradeAmount);
							let pipValue = Number(position.pipValue);
							const stopOut = Number(position.stopOut);
							const multiplier = Number(position.multiplier);
							console.log("MULTIPLIER", multiplier);

							if (position.preStopLoss) {
								pipValue = pipValue * 2;
							}

							if (position.type === "Buy" && currentPrice > Number(lastBarRef.current.close)) {
								toast.error("Stop Loss no puede ser mayor al precio");
								return;
							} else if (position.type === "Sell" && currentPrice < Number(lastBarRef.current.close)) {
								toast.error("Stop Loss no puede ser menor al precio");
								return;
							}

							const SLPrice = this.getPrice();
							const pipsSL = Math.abs(SLPrice - entryPrice);
							slProfit.current = pipsSL * pipValue * tradeAmount;
							console.log("SLPRICE", SLPrice, entryPrice, pipsSL, pipValue, tradeAmount, stopOut, multiplier);

							// const pipsSL = Math.abs(entryPrice - this.getPrice());
							// slProfit.current = (tradeAmount / stopOut) * pipsSL * multiplier;
							this.setText(`SL: $${slProfit.current.toFixed(2)} -- Pips: ${pipsSL.toFixed(2)}`);
							updateStopLoss(contractId, this.getPrice(), selectedAccount, pipsSL, slProfit.current);

							existingLinesRef.current[slKey].remove();
							delete existingLinesRef.current[slKey];
						}
					})
					.onCancel("onClose called", function (text) {
						if (Number(this.getTooltip()) === Number(contractId)) {
							const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
							removeStopLoss(contractId, selectedAccount);
						}
					});

				// if (Number(existingLinesRef.current[slKey].getTooltip()) === Number(contractId) && Number(position.stopLoss) > 0) {
				// 	const currentPrice = existingLinesRef.current[slKey].getPrice();
				// 	const entryPrice = position.orderPosition ? Number(position.orderPrice) : Number(position.entryPrice);
				// 	const pipValue = Number(position.pipValue);
				// 	const riskAmount = Number(position.riskAmount);

				// const pipsSL = Math.abs(entryPrice - currentPrice);
				// 	const riskPerPipMove = pipsSL * pipValue;
				// 	const tradeAmount = riskAmount / riskPerPipMove;

				// tpProfit.current = pipsSL * pipValue * tradeAmount;
				// 	existingLinesRef.current[slKey].setText(
				// 		position.preStopLoss
				// 			? `STOP OUT: $${position.slProfit} -- Pips: ${position.pipsSL}`
				// 			: position.orderPosition
				// 			? `Order SL: $${position.slProfit} -- Pips: ${position.pipsSL}`
				// 			: `SL: $${position.slProfit} -- Pips: ${position.pipsSL}`
				// 	);
				// 	existingLinesRef.current[slKey].setQuantity(Number(tradeAmount).toFixed(2));
				// }
			} else if (type === "takeProfit") {
				existingLinesRef.current[tpKey] = line
					.setText(
						position.orderPosition
							? `Orden TP: $${position.tpProfit} -- Pips: ${position.pipsTP}`
							: `TP: $${position.tpProfit} -- Pips: ${position.pipsTP}`
					)
					.setPrice(
						position.takeProfit
							? position.takeProfit
							: position.type === "Buy"
							? position.entryPrice + 10 * position.pip
							: position.entryPrice - 10 * position.pip
					)
					.setLineColor("#35e737")
					.setQuantity(Number(position.tradeAmount).toFixed(2))
					.setQuantityBorderColor("#35e737")
					.setBodyTextColor("#35e737")
					.setQuantityTextColor("#a9a9a9")
					.setBodyBorderColor("#35e737")
					.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
					.setQuantityBackgroundColor("rgba(0, 0, 0, 0)")
					.setCancelButtonBackgroundColor("rgba(0, 0, 0, 0)")
					.setCancelButtonIconColor("#35e737")
					.setCancelButtonBorderColor("#35e737")
					.setTooltip(contractId)
					.onMove(function () {
						if (Number(this.getTooltip()) === contractId) {
							existingLinesRef.current[`position_${contractId}`].setQuantityBackgroundColor("rgba(0, 0, 0, 0)");
							const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
							const currentPrice = this.getPrice();
							const entryPrice = position.orderPosition ? Number(position.orderPrice) : Number(position.entryPrice);
							const tradeAmount = Number(position.tradeAmount);

							if (position.type === "Buy" && currentPrice < Number(lastBarRef.current.close)) {
								toast.error("Take Profit no puede ser menor al precio de entrada");
								return;
							} else if (position.type === "Sell" && currentPrice > Number(lastBarRef.current.close)) {
								toast.error("Take Profit no puede ser mayor al precio de entrada");
								return;
							}

							const pipsTP = Math.abs(entryPrice - currentPrice);
							tpProfit.current = pipsTP * Number(position.pipValue) * tradeAmount;
							this.setText(`TP: $${tpProfit.current.toFixed(2)} -- Pips: ${pipsTP.toFixed(2)}`);
							this.setQuantity(tradeAmount.toFixed(2));
							updateTakeProfit(contractId, this.getPrice(), selectedAccount, pipsTP, tpProfit.current);

							existingLinesRef.current[tpKey].remove();
							delete existingLinesRef.current[tpKey];
						}
					})
					.onCancel("onClose called", function (text) {
						if (Number(this.getTooltip()) === contractId) {
							const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
							removeTakeProfit(contractId, selectedAccount);
						}
					});

				// if (Number(existingLinesRef.current[tpKey].getTooltip()) === contractId && Number(position.takeProfit) > 0) {
				// 	const currentPrice = existingLinesRef.current[tpKey].getPrice();
				// 	const entryPrice = position.orderPosition ? Number(position.orderPrice) : Number(position.entryPrice);
				// 	const pipValue = Number(position.pipValue);
				// 	const pipsTP = Math.abs(entryPrice - currentPrice);
				// 	const tradeAmount = Number(existingLinesRef.current[slKey].getQuantity()); // Usamos el tradeAmount calculado del SL

				// 	tpProfit.current = pipsTP * pipValue * tradeAmount;
				// 	existingLinesRef.current[tpKey].setText(
				// 		position.orderPosition
				// 			? `Orden TP: $${position.tpProfit} -- Pips: ${position.pipsTP}`
				// 			: `TP: $${position.tpProfit} -- Pips: ${position.pipsTP}`
				// 	);
				// 	existingLinesRef.current[tpKey].setQuantity(tradeAmount.toFixed(2));
				// }
			}
		};

		const handleClosePosition = (data) => {
			// console.log("SOCKET CLOSE POSITION");

			if (data.symbol === symbolInfoRef.current.symbol) {
				const contractId = data.contractId;

				// Eliminar la línea de posición
				const positionKey = `position_${contractId}`;
				if (existingLinesRef.current[positionKey]) {
					delete existingLinesRef.current[positionKey];
				}

				// Eliminar la línea de Stop Loss
				const slKey = `stopLoss_${contractId}`;
				if (existingLinesRef.current[slKey]) {
					delete existingLinesRef.current[slKey];
				}

				// Eliminar la línea de Take Profit
				const tpKey = `takeProfit_${contractId}`;
				if (existingLinesRef.current[tpKey]) {
					delete existingLinesRef.current[tpKey];
				}
			}
		};

		function removeStopLoss(contractId, sA) {
			socket.emit("modifyPosition", {
				account: sA.account,
				userId: sA.userId,
				contractId: contractId,
				priceSL: null,
				modify: "SL",
			});
		}

		function removeTakeProfit(contractId, sA) {
			socket.emit("modifyPosition", {
				account: sA.account,
				userId: sA.userId,
				contractId: contractId,
				priceTP: null,
				modify: "TP",
			});
		}

		const removeLine = (contractId, type) => {
			const key = `${type}_${contractId}`;
			delete existingLinesRef.current[key];
		};

		const handleAllOrder = (data) => {
			// console.log("ALL ORDERS", existingOrdersRef.current);

			if (data === null) return;

			Object.keys(existingOrdersRef.current).forEach((key) => {
				if (existingOrdersRef.current[key].remove) {
					existingOrdersRef.current[key].remove();
				}
				delete existingOrdersRef.current[key];
			});

			data.forEach((order) => {
				if (order.symbol === symbolInfoRef.current.symbol) {
					const orderKey = `order_${order.contractId}`;
					const slKey = `orderSL_${order.contractId}`;
					const tpKey = `orderTP_${order.contractId}`;

					// Crear línea de orden tvWidgetRef.current.chart().createPositionLine();
					existingOrdersRef.current[orderKey] = tvWidgetRef.current
						.chart()
						.createPositionLine()
						.setText(`Orden ${order.type}`)
						.setQuantity(Number(order.riskAmount).toFixed(2))
						.setPrice(order.orderPrice)
						.setLineColor("blue")
						.setQuantityBorderColor("blue")
						.setBodyTextColor("#a9a9a9")
						.setQuantityTextColor("#a9a9a9")
						.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
						.setQuantityBackgroundColor("rgba(0, 0, 0, 0)")
						.setCloseButtonBackgroundColor("rgba(0, 0, 0, 0)")
						.setCloseButtonIconColor("#a9a9a9")
						.setCloseButtonBorderColor("blue")
						.setBodyBorderColor("blue")
						.setTooltip(order.contractId)
						.onClose("onClose called", function (text) {
							let forCancel = {
								symbol: order.symbol,
								contractId: order.contractId,
								userId: order.userId,
							};
							socket.emit("closeOrder", forCancel);
						});

					// Crear línea de Stop Loss si existe
					if (order.stopLoss) {
						existingOrdersRef.current[slKey] = tvWidgetRef.current
							.chart()
							.createOrderLine()
							.setText(`Order Stop Loss`)
							.setPrice(order.stopLoss)
							.setLineColor("orange")
							.setQuantity(Number(order.riskAmount).toFixed(2))
							.setQuantityBorderColor("orange")
							.setBodyTextColor("orange")
							.setQuantityTextColor("#a9a9a9")
							.setBodyBorderColor("orange")
							.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
							.setQuantityBackgroundColor("rgba(0, 0, 0, 0)")
							.setTooltip(order.contractId);
					}

					// Crear línea de Take Profit si existe
					if (order.takeProfit) {
						existingOrdersRef.current[tpKey] = tvWidgetRef.current
							.chart()
							.createOrderLine()
							.setText(`Order Take Profit`)
							.setPrice(order.takeProfit)
							.setLineColor("#35e737")
							.setQuantity(Number(order.riskAmount).toFixed(2))
							.setQuantityBorderColor("#35e737")
							.setBodyTextColor("#35e737")
							.setQuantityTextColor("#a9a9a9")
							.setBodyBorderColor("#35e737")
							.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
							.setQuantityBackgroundColor("rgba(0, 0, 0, 0)")
							.setTooltip(order.contractId);
					}
				}
			});
		};

		const handleCloseOrder = (data) => {
			// console.log("SOCKET CLOSE ORDER", data);
			if (data.symbol === symbolInfoRef.current.symbol) {
				if (slOrderLineRef.current) {
					slOrderLineRef.current.remove();
					slOrderLineRef.current = null;
				}
				if (tpOrderLineRef.current) {
					tpOrderLineRef.current.remove();
					tpOrderLineRef.current = null;
				}
				if (orderRef.current) {
					orderRef.current.remove();
					orderRef.current = null;
				}
				orderPosition = false;
			}

			const orderKey = `order_${data.contractId}`;
			const slKey = `orderSL_${data.contractId}`;
			const tpKey = `orderTP_${data.contractId}`;

			// Eliminar la línea de orden
			if (existingOrdersRef.current[orderKey]) {
				existingOrdersRef.current[orderKey].remove();
				delete existingOrdersRef.current[orderKey];
			}

			// Eliminar la línea de Stop Loss
			if (existingOrdersRef.current[slKey]) {
				existingOrdersRef.current[slKey].remove();
				delete existingOrdersRef.current[slKey];
			}

			// Eliminar la línea de Take Profit
			if (existingOrdersRef.current[tpKey]) {
				existingOrdersRef.current[tpKey].remove();
				delete existingOrdersRef.current[tpKey];
			}
		};

		const handleNewOrder = (data) => {
			slOrderLineRef.current.remove();
			slOrderLineRef.current = null;
			tpOrderLineRef.current.remove();
			tpOrderLineRef.current = null;
			orderRef.current.remove();
			orderRef.current = null;
			orderPosition = false;
		};

		const handleModifyPositions = (data) => {
			// console.log("SOCKET MODIFY POSITIONS", data);
			const { contractId, modify } = data;
			if (modify === "SL" && data.priceSL === null) {
				removeLine(contractId, "stopLoss");
			} else if (modify === "TP" && data.priceTP === null) {
				removeLine(contractId, "takeProfit");
			}
		};

		const handleBalance = (data) => {
			// console.log("BALANCE", data);
			// const dataBalance = JSON.parse(data);
			// if (!dataBalance.balance) return;

			// const balance = data;
			// const account = dataBalance.account;

			// const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount")) || {};
			// if (account === selectedAccount.account) {
			store.getState().setBalance(Number(data).toFixed(2));
			// }
		};

		const handleMessages = (data, callback) => {
			const message = data.message;
			if (message === "refresh") {
				window.location.reload();
				return;
			}

			toast.error(message, {
				position: "top-center",
				autoClose: false,
				closeOnClick: false,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
				closeButton: true,
			});

			if (callback) {
				callback(true);
			}
		};

		const handleOptions = (data) => {
			console.log("SOCKET OPTIONS", data);
			if (data.length === 0) {
				return;
			}

			const tvWidget = store.getState().chart;
			const symbolInfo = store.getState().symbolInfo;

			if (symbolInfo === null || tvWidget === null) {
				setTimeout(() => {
					handleOptions(data);
				}, 500);
				return;
			}

			data.forEach((option) => {
				if (option.symbol === symbolInfo.symbol) {
					// if (option.contractType && option.contractType !== "accumulators") {
					// 	accumBodyRef.current.contractId = option.contractId;
					// console.log("OPTION", option);
					// }
					if (option.status === "win" || option.status === "loss") {
						const resultColor = option.status === "win" ? "green" : "red";
						if (option.optionType === "riseFall") {
							checkOptionResult(option, tvWidget, resultColor);
						}
					} else {
						handleOpenOption(option);

						// if (option.optionType === "riseFall") {
						// 	const startTime = option.entryTime;
						// 	const endTime = startTime + option.duration;
						// 	const price = option.optionType === "riseFall" ? option.entryPrice : Number(option.entryPrice) + Number(option.barrier);

						// 	tvWidget.activeChart().createMultipointShape(
						// 		[
						// 			{ time: startTime, price: price },
						// 			{ time: endTime, price: price },
						// 		],
						// 		{
						// 			shape: "trend_line",
						// 			lock: true,
						// 			disableSelection: true,
						// 			disableSave: true,
						// 			disableUndo: true,
						// 			text: `${option.contractType.toUpperCase()} - Pending`,
						// 			overrides: {
						// 				linecolor: "orange",
						// 				linewidth: 0.5,
						// 				statsPosition: 3,
						// 				textcolor: "white",
						// 				fontsize: 12,
						// 				bold: true,
						// 			},
						// 		}
						// 	);
						// }
					}
				}
			});
		};

		const checkOptionResult = async (option, tvWidget, resultColor) => {
			tvWidget.activeChart().createMultipointShape(
				[
					{ time: option.entryTime, price: option.entryPrice },
					{ time: option.closeTime, price: option.entryPrice },
				],
				{
					shape: "trend_line",
					lock: true,
					disableSelection: true,
					disableSave: true,
					disableUndo: true,
					text: `${option.contractType} - Pending`,
					overrides: {
						linecolor: resultColor,
						linewidth: 0.5,
						statsPosition: 3,
						textcolor: "white",
						fontsize: 12,
						bold: true,
					},
				}
			);

			tvWidget.activeChart().createMultipointShape(
				[
					{ time: option.closeTime, price: option.entryPrice },
					{ time: option.closeTime, price: option.closePrice },
				],
				{
					shape: "trend_line",
					lock: true,
					disableSelection: true,
					disableSave: true,
					disableUndo: true,
					overrides: {
						linecolor: resultColor,
						linestyle: 2, // Dotted line
						linewidth: 1,
					},
				}
			);

			// const profit = option.profit < 0 ? option.profit : option.profit - option.tradeAmount;

			tvWidget.activeChart().createShape(
				{ time: option.closeTime, price: option.closePrice },
				{
					shape: "balloon",
					text: option.profit,
					overrides: {
						backgroundColor: resultColor,
						color: "white",
						fontsize: 20,
						bold: true,
					},
					zOrder: "top",
				}
			);
		};

		socket.on("allLines", handleAllLines);
		socket.on("allOptions", handleOptions);
		socket.on("allOrders", handleAllOrder);
		socket.on("newOrder", handleNewOrder);
		socket.on("closeLine", handleClosePosition);
		socket.on("closeOrder", handleCloseOrder);
		socket.on("modifyPosition", handleModifyPositions);
		socket.on("balance", handleBalance);
		socket.on("messages", (data, callback) => {
			handleMessages(data, callback);
		});

		return () => {
			socket.off("allLines", handleAllLines);
			socket.off("allOptions", handleOptions);
			socket.off("allOrders", handleAllOrder);
			socket.off("newOrder", handleNewOrder);
			socket.off("closeLine", handleClosePosition);
			socket.off("closeOrder", handleCloseOrder);
			socket.off("modifiyPositions", handleModifyPositions);
			socket.off("balance", handleBalance);
			socket.off("messages", handleMessages);
		};
	}, []);

	function updateStopLoss(contractId, price, sA, pipsSL, slProfit) {
		socket.emit("modifyPosition", {
			account: sA.account,
			userId: sA.userId,
			contractId: contractId,
			priceSL: price,
			modify: "SL",
			pipsSL: pipsSL.toFixed(2),
			slProfit: slProfit.toFixed(2),
		});
	}

	function updateTakeProfit(contractId, price, sA, pipsTP, tpProfit) {
		socket.emit("modifyPosition", {
			account: sA.account,
			userId: sA.userId,
			contractId: contractId,
			priceTP: price,
			modify: "TP",
			pipsTP: pipsTP.toFixed(2),
			tpProfit: tpProfit.toFixed(2),
		});
	}

	//==============================================// OEDER LINES
	useEffect(() => {
		if (!tvWidget) return;
		tvWidgetRef.current = tvWidget;
		tvWidget.subscribe("onPlusClick", (params) => {
			console.log("ON PLUS CLICK", params);
			const price = params.price.toFixed(2);
			drawOrderLines(price);
		});
	}, [tvWidget]);

	const drawOrderLines = (price) => {
		console.log("DRAW ORDER LINES", price);
		if (!tvWidget) return;
		orderPosition = true;

		const percentageDistance = 0.001;
		price = Number(price);
		// const price = Number(lastBarRef.current.close);
		const distance = price * percentageDistance;

		SLOrder.current = price + distance;
		pipsOrderSL.current = Math.abs(price - SLOrder.current);

		TPOrder.current = price - distance;
		pipsOrderTP.current = Math.abs(price - TPOrder.current);

		const stopOut = Number(symbolInfoRef.current.stopOut);
		const pipValue = Number(riskAmountRef.current) / stopOut;
		const riskPerPipMove = pipsOrderSL.current * pipValue;
		const tradeAmount = Number(riskAmountRef.current) / riskPerPipMove;
		setTradeAmount(tradeAmount.toFixed(2));
		let lastPrice = price;

		if (orderRef.current) {
			orderRef.current.remove();
			orderRef.current = null;
			orderPosition = false;
		} else {
			orderRef.current = tvWidget
				.chart()
				.createOrderLine()
				.onMove(function () {
					let newPrice = this.getPrice();

					let priceChange = newPrice - lastPrice;

					// Mover SL y TP la misma cantidad que la línea de Orden
					if (slOrderLineRef.current) {
						slOrderLineRef.current.setPrice(SLOrder.current + priceChange);
					}
					if (tpOrderLineRef.current) {
						tpOrderLineRef.current.setPrice(TPOrder.current + priceChange);
					}

					SLOrder.current += priceChange;
					TPOrder.current += priceChange;
					lastPrice = newPrice;
				})
				.onCancel(function () {
					console.log("onCancel called");
					slOrderLineRef.current.remove();
					slOrderLineRef.current = null;
					tpOrderLineRef.current.remove();
					tpOrderLineRef.current = null;
					orderRef.current.remove();
					orderRef.current = null;
					orderPosition = false;
				})
				.setText(`ORDER: $${price}`)
				.setQuantity(Number(riskAmountRef.current).toFixed(2))
				.setPrice(price)
				.setLineColor("#4599d9")
				.setQuantityBorderColor("#4599d9")
				.setBodyBorderColor("#4599d9")
				.setBodyTextColor("#a9a9a9")
				.setQuantityTextColor("#a9a9a9")
				.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
				.setQuantityBackgroundColor("rgba(0, 0, 0, 0)")
				.setCancelButtonBackgroundColor("rgba(0, 0, 0, 0)")
				.setCancelButtonIconColor("#4599d9")
				.setCancelButtonBorderColor("#4599d9");
		}

		if (slOrderLineRef.current) {
			slOrderLineRef.current.remove();
			slOrderLineRef.current = null;
		} else {
			slOrderLineRef.current = tvWidget
				.chart()
				.createOrderLine()
				.onMove(function () {
					const priceNew = orderRef.current.getPrice();
					SLOrder.current = slOrderLineRef.current.getPrice();
					console.log("SLOrder.current", SLOrder.current);
					pipsOrderSL.current = Math.abs(priceNew - SLOrder.current);
					console.log("pipsOrderSL.current", pipsOrderSL.current);

					const derivMultiplier = Number(symbolInfoRef.current.derivMultiplier);
					const multiplier2 = Number(symbolInfoRef.current.multiplier2);
					const derivMultiplier2 = Number(symbolInfoRef.current.derivMultiplier2);
					const multiplier3 = Number(symbolInfoRef.current.multiplier3);
					const derivMultiplier3 = Number(symbolInfoRef.current.derivMultiplier3);
					const stopOut = Number(symbolInfoRef.current.stopOut);
					const pipValue = Number(symbolInfoRef.current.pipValue);
					const riskPerPipMove = pipsOrderSL.current * pipValue;
					const tradeAmount = Number(riskAmountRef.current) / riskPerPipMove;

					let chosenDerivMultiplier = derivMultiplier;
					let chosenMultiplier = 1;

					if (stopOut / multiplier2 > pipsOrderSL.current) {
						chosenDerivMultiplier = derivMultiplier2;
						chosenMultiplier = multiplier2;
					}
					if (stopOut / multiplier3 > pipsOrderSL.current) {
						chosenDerivMultiplier = derivMultiplier3;
						chosenMultiplier = multiplier3;
					}

					let adjustedTradeAmount = tradeAmount;

					if (chosenDerivMultiplier === derivMultiplier2) {
						adjustedTradeAmount = tradeAmount / multiplier2;
					} else if (chosenDerivMultiplier === derivMultiplier3) {
						adjustedTradeAmount = tradeAmount / multiplier3;
					}
					multiplierRef.current = chosenMultiplier;
					derivMultiplierRef.current = chosenDerivMultiplier;

					tradeAmountRef.current = adjustedTradeAmount.toFixed(2);
					setTradeAmount(adjustedTradeAmount.toFixed(2));
					this.setQuantity(Number(adjustedTradeAmount).toFixed(2));

					slProfit.current = Number(riskAmountRef.current);
					this.setText(`ORDER SL: $${riskAmountRef.current} -- Pips: ${pipsOrderSL.current.toFixed(2)} X: ${chosenDerivMultiplier}`);

					if (derivMultiplier !== derivMultiplierRef.current) {
						newPipValueRef.current = pipValue * multiplierRef.current;
					} else {
						newPipValueRef.current = pipValue;
					}

					tpProfit.current = pipsOrderTP.current * newPipValueRef.current * adjustedTradeAmount;
					tpOrderLineRef.current.setText(`ORDER TP: $${tpProfit.current.toFixed(2)} -- Pips: ${pipsOrderTP.current.toFixed(2)}`);

					if (tpOrderLineRef.current) {
						tpOrderLineRef.current.setQuantity(Number(adjustedTradeAmount).toFixed(2));
					}
				})
				.onCancel(function () {})
				.setText(`ORDER SL: $${riskAmountRef.current} -- Pips: ${pipsOrderSL.current.toFixed(2)}  X: ${derivMultiplierRef.current}`)
				.setQuantity(Number(tradeAmount).toFixed(2))
				.setPrice(SLOrder.current)
				.setLineColor("#f74712")
				.setQuantityBorderColor("#f74712")
				.setBodyTextColor("#f74712")
				.setBodyBorderColor("#f74712")
				.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
				.setQuantityBackgroundColor("rgba(0, 0, 0, 0)")
				.setCancelButtonBackgroundColor("rgba(0, 0, 0, 0)")
				.setCancelButtonIconColor("#f74712")
				.setCancelButtonBorderColor("#f74712");
		}

		if (tpOrderLineRef.current) {
			tpOrderLineRef.current.remove();
			tpOrderLineRef.current = null;
		} else {
			tpOrderLineRef.current = tvWidget
				.chart()
				.createOrderLine()
				.onMove(function () {
					const priceNew = Number(lastBarRef.current.close);
					TPOrder.current = tpOrderLineRef.current.getPrice();
					pipsOrderTP.current = Math.abs(priceNew - TPOrder.current);

					console.log("TP PROFIT", pipsOrderTP.current, newPipValueRef.current, tradeAmountRef.current);
					tpProfit.current = pipsOrderTP.current * newPipValueRef.current * tradeAmountRef.current;
					this.setText(`ORDER TP: $${tpProfit.current.toFixed(2)} -- Pips: ${pipsOrderTP.current.toFixed(2)}`);
				})
				.onCancel(function () {})
				.setText(`ORDER TP: $${riskAmountRef.current} -- Pips: ${pipsOrderTP.current.toFixed(2)}`)
				.setQuantity(Number(tradeAmountRef.current).toFixed(2))
				.setPrice(TPOrder.current)
				.setLineColor("#35e737")
				.setQuantityBorderColor("#35e737")
				.setBodyBorderColor("#35e737")
				.setBodyTextColor("#35e737")
				.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
				.setQuantityBackgroundColor("rgba(0, 0, 0, 0)")
				.setCancelButtonBackgroundColor("rgba(0, 0, 0, 0)")
				.setCancelButtonIconColor("#35e737")
				.setCancelButtonBorderColor("#35e737");
		}
	};

	const [timer, setTimer] = useState(null);

	const handleInputChange = (event) => {
		if (!event || !event.target) {
			console.error("Evento o target del evento no definido");
			return;
		}

		const value = event.target.value;
		localStorage.setItem("inputValue", value);
		setInputValue(value);
		handleProposal();
		if (timer) {
			clearTimeout(timer);
		}
		const newTimer = setTimeout(() => {
			calculateAmount(value);
		}, 500);

		setTimer(newTimer);
	};

	const calculateAmount = (value) => {
		let porcent = Number(value) / 100;
		const balance = store.getState().balance;
		actualBalanceRef.current = Number(balance);
		calculatedValueRef.current = actualBalanceRef.current * porcent;

		if (calculatedValueRef.current < 1) {
			toast.error("El porcentaje debe ser al menos 1", {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				theme: "dark",
			});
			return;
		}

		setRiskAmount(calculatedValueRef.current.toFixed(2));
		localStorage.setItem("riskAmount", calculatedValueRef.current.toFixed(2));
		riskAmountRef.current = calculatedValueRef.current.toFixed(2);

		if (slLineRef.current) {
			slLineRef.current.setText(`SL: $${riskAmount} -- Pips: ${pipsSL.current.toFixed(2)}`);
		}

		if (tpLineRef.current) {
			tpLineRef.current.setQuantity(Number(tradeAmount).toFixed(2));
		}
	};

	async function buyMultUpMultDn(contractType) {
		const currentPrice = lastBarRef.current.close;
		if (!orderPosition) {
			if (!retCandles && !slTpButton) {
				toast.error("No tienes un metodo de gestión de riesgo seleccionado");
				return;
			}
		}

		if (!selectedAccount || !selectedAccount.account) {
			toast.error("No tienes una cuenta seleccionada");
			return;
		}

		if (!symbolInfo || !symbolInfo.symbol) {
			toast.error("No se ha seleccionado un símbolo válido");
			return;
		}

		if (!user || !user.id) {
			toast.error("No se ha identificado un usuario válido");
			return;
		}

		if (contractType === "MULTDOWN" && slLineRef.current && slLineRef.current.getPrice() < currentPrice) {
			toast.error("Para operaciones de venta, el Stop Loss no puede ser menor que el precio actual");
			return;
		}

		if (contractType === "MULTUP" && tpLineRef.current && tpLineRef.current.getPrice() < currentPrice) {
			toast.error("Para operaciones de compra, el Take Profit no puede ser menor que el precio actual");
			return;
		}

		let price = await Number(store.getState().lastBar.close);
		let orderPrice;
		let orderType;

		const showToast = (condition, orderMessage, priceMessage) => {
			if (condition) {
				toast.error(orderPosition ? orderMessage : priceMessage);
				return true;
			}
			return false;
		};

		if (contractType === "MULTDOWN") {
			if (orderPosition) {
				orderPrice = orderRef.current.getPrice();
				orderType = orderPrice > price ? "Stop" : "Limit";
				if (showToast(orderRef.current.getPrice() > SLOrder.current, "La orden debe ser menor al Stop Loss", null)) return;
				if (showToast(orderRef.current.getPrice() < TPOrder.current, "La orden debe ser mayor al Take Profit", null)) return;
			}
		}

		if (contractType === "MULTUP") {
			if (orderPosition) {
				orderPrice = orderRef.current.getPrice();
				orderType = orderPrice < price ? "Stop" : "Limit";
				if (showToast(orderRef.current.getPrice() < SLOrder.current, "La orden debe ser mayor al Stop Loss", null)) return;
				if (showToast(orderRef.current.getPrice() > TPOrder.current, "La orden debe ser menor al Take Profit", null)) return;
			}
		}

		const derivMultiplier = symbolInfo.derivMultiplier;
		const multiplier2 = symbolInfo.multiplier2;
		const derivMultiplier2 = symbolInfo.derivMultiplier2;
		const multiplier3 = symbolInfo.multiplier3;
		const derivMultiplier3 = symbolInfo.derivMultiplier3;
		const stopOut = Number(symbolInfo.stopOut);
		const pipsSLValue = pipsSL.current || 0;

		let chosenDerivMultiplier = derivMultiplier;
		let chosenMultiplier = derivMultiplier;

		if (stopOut / multiplier2 > pipsSLValue) {
			chosenDerivMultiplier = derivMultiplier2;
			chosenMultiplier = multiplier2;
		}
		if (stopOut / multiplier3 > pipsSLValue) {
			chosenDerivMultiplier = derivMultiplier3;
			chosenMultiplier = multiplier3;
		}
		console.log("chosenDerivMultiplier:", chosenDerivMultiplier);
		console.log("chosenMultiplier:", chosenMultiplier);

		let adjustedTradeAmount = tradeAmount;

		if (chosenMultiplier === derivMultiplier2) {
			adjustedTradeAmount = tradeAmount / multiplier2;
		} else if (chosenMultiplier === derivMultiplier3) {
			adjustedTradeAmount = tradeAmount / multiplier3;
		}

		const body = {
			stopLoss: orderPosition
				? SLOrder.current.toFixed(2)
				: slLineRef.current && slLineRef.current.getPrice()
				? slLineRef.current.getPrice()
				: slRetCandlesRef.current
				? slRetCandlesRef.current.getPrice()
				: null,
			takeProfit: orderPosition
				? TPOrder.current.toFixed(2)
				: tpLineRef.current && tpLineRef.current.getPrice()
				? tpLineRef.current.getPrice()
				: null,
			stopOut: Number(symbolInfo.stopOut),
			contractType,
			type: contractType === "MULTUP" ? (orderPosition ? "Buy " + orderType : "Buy") : orderPosition ? "Sell " + orderType : "Sell",
			symbol: symbolInfo.symbol,
			symbolName: symbolInfo.displayName,
			pip: Number(symbolInfo.pip),
			pipValue: newPipValueRef.current || 0,
			userId: user.id,
			tradeAmount: Number(adjustedTradeAmount).toFixed(2) || 0,
			account: selectedAccount.account,
			derivMultiplier: chosenDerivMultiplier,
			multiplier: chosenMultiplier,
			riskAmount: riskAmountRef.current,
			pipsSL: orderPosition ? pipsOrderSL.current.toFixed(2) : pipsSLValue.toFixed(2),
			pipsTP: orderPosition ? pipsOrderTP.current.toFixed(2) : pipsTP.current ? pipsTP.current.toFixed(2) : null,
			tpProfit: tpProfit.current ? tpProfit.current.toFixed(2) : null,
			slProfit: slProfit.current ? slProfit.current.toFixed(2) : null,
			preStopLoss: preStopLoss ? preStopLoss : null,
			orderType: orderPosition ? orderType : "Market",
			orderPrice: orderPosition ? orderPrice : "0",
			orderPosition: orderPosition,
		};
		console.log("CONTRACT BODY", body);

		if (orderPosition) {
			socket.emit("buyMultUpMultDn", body);

			if (slOrderLineRef.current) {
				slOrderLineRef.current.remove();
				slOrderLineRef.current = null;
			}
			if (tpOrderLineRef.current) {
				tpOrderLineRef.current.remove();
				tpOrderLineRef.current = null;
			}
			if (orderRef.current) {
				orderRef.current.remove();
				orderRef.current = null;
			}
			orderPosition = false;
		} else {
			socket.emit("buyMultUpMultDn", body);

			if (slLineRef.current) {
				slLineRef.current.remove();
				slLineRef.current = null;
			}

			if (tpLineRef.current) {
				tpLineRef.current.remove();
				tpLineRef.current = null;
			}

			setSlTpButton(false);
			setRetCandles(false);
		}

		if (slRetCandlesRef.current) {
			slRetCandlesRef.current.remove();
			slRetCandlesRef.current = null;
		}
	}

	async function buyOptions(type) {
		if (!selectedAccount || !selectedAccount.token) {
			toast.error("Selecciona una cuenta antes de realizar una operación", {
				position: "top-right",
				autoClose: 2000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				theme: "dark",
			});
			return;
		}

		if (!selectedAccount || !selectedAccount.account) {
			toast.error("No tienes una cuenta seleccionada", {
				position: "top-right",
				autoClose: 2000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				theme: "dark",
			});
			return;
		}

		if (!symbolInfoRef.current || !symbolInfoRef.current.symbol) {
			toast.error("No se ha seleccionado un símbolo válido", {
				position: "top-right",
				autoClose: 2000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				theme: "dark",
			});
			return;
		}

		if (!user || !user.id) {
			toast.error("No se ha identificado un usuario válido", {
				position: "top-right",
				autoClose: 2000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				theme: "dark",
			});
			return;
		}
		switch (contractTypeRef.current) {
			case "riseFall":
				const rfBody = {
					optionType: "riseFall",
					contractType: type,
					symbol: symbolInfoRef.current.symbol,
					symbolName: symbolInfoRef.current.displayName,
					userId: user.id,
					tradeAmount: riskAmountRef.current,
					account: selectedAccount.account,
					duration: sliderRef.current,
					basis: "stake",
				};
				socket.emit("buyOptions", rfBody);
				break;
			case "higherLower":
				const hlBody = {
					optionType: "higherLower",
					contractType: type,
					symbol: symbolInfoRef.current.symbol,
					symbolName: symbolInfoRef.current.displayName,
					userId: user.id,
					tradeAmount: riskAmountRef.current,
					account: selectedAccount.account,
					duration: sliderRef.current,
					basis: "stake",
					barrier: barrierRef.current,
				};
				socket.emit("buyOptions", hlBody);
				break;
			case "touchNoTouch":
				const tntBody = {
					optionType: "touchNoTouch",
					contractType: type,
					symbol: symbolInfoRef.current.symbol,
					symbolName: symbolInfoRef.current.displayName,
					userId: user.id,
					tradeAmount: riskAmountRef.current,
					account: selectedAccount.account,
					duration: sliderRef.current,
					basis: "stake",
					barrier: barrierRef.current,
				};
				socket.emit("buyOptions", tntBody);
				break;
			case "accumulators":
				accumBodyRef.current = {
					optionType: "accumulators",
					contractType: type,
					symbol: symbolInfoRef.current.symbol,
					symbolName: symbolInfoRef.current.displayName,
					userId: user.id,
					tradeAmount: riskAmountRef.current,
					account: selectedAccount.account,
					basis: "stake",
					growthRate: Number(sliderRef.current) / 100,
					contractId: accumBodyRef.current === null ? null : accumBodyRef.current.contractId,
					profitTick: (profitTick * ((Number(sliderRef.current) / 100) * Number(riskAmountRef.current))).toFixed(2),
				};
				socket.emit("buyOptions", accumBodyRef.current);
				break;
			default:
				toast.error("Tipo de contrato no válido");
				return;
		}
	}

	const handleInputRetCandles = (e) => {
		if (!e || !e.target) {
			console.error("EVENTO O TARGET DEL EVENTO NO DEFINIDO");
			return;
		}

		//LLAMAR A LA FUNCION Y TRAER VELAS Y ALMACENARLAS EN EL STORE
		setRetCandlesQty(e.target.value);
		localStorage.setItem("retCandleQty", e.target.value);

		const customCandles = store.getState().customCandles;
		const lastTenCandles = customCandles.slice(-20);
		const bullishCandles = lastTenCandles.filter((candle) => candle.close > candle.open);
		const bearishCandles = lastTenCandles.filter((candle) => candle.close < candle.open);
		let relevantCandles = bullishCandles.length > bearishCandles.length ? bullishCandles : bearishCandles;
		const openCloseDifferences = relevantCandles.map((candle) => {
			return Math.abs(candle.close - candle.open);
		});

		const averageOpenCloseDifference = openCloseDifferences.reduce((sum, diff) => sum + diff, 0) / openCloseDifferences.length;
		const stopLossDistance = averageOpenCloseDifference * Number(e.target.value);
		const currentPrice = Number(lastBarRef.current.close);
		const displayName = symbolInfoRef.current.displayName;
		let newStopLossPrice = null;
		let newTakeProfitPrice = null;

		if (displayName.startsWith("Boom")) {
			newStopLossPrice = currentPrice - stopLossDistance;
			newTakeProfitPrice = currentPrice + stopLossDistance;
		} else {
			newStopLossPrice = currentPrice + stopLossDistance;
			newTakeProfitPrice = currentPrice - stopLossDistance;
		}

		pipsSL.current = Math.abs(currentPrice - newStopLossPrice);
		console.log("pipsSL.current", pipsSL.current);
		pipsTP.current = Math.abs(currentPrice - newStopLossPrice);

		const stopOut = Number(symbolInfo.stopOut);
		console.log("stopOut", stopOut);
		const pipValue = Number(symbolInfo.pipValue);
		console.log("pipValue", pipValue);

		const derivMultiplier = Number(symbolInfoRef.current.derivMultiplier);
		const multiplier2 = Number(symbolInfoRef.current.multiplier2);
		const derivMultiplier2 = Number(symbolInfoRef.current.derivMultiplier2);
		const multiplier3 = Number(symbolInfoRef.current.multiplier3);
		const derivMultiplier3 = Number(symbolInfoRef.current.derivMultiplier3);

		let chosenDerivMultiplier = derivMultiplier;
		let chosenMultiplier = 1;

		if (stopOut / multiplier2 > pipsSL.current) {
			chosenDerivMultiplier = derivMultiplier2;
			chosenMultiplier = multiplier2;
		}
		if (stopOut / multiplier3 > pipsSL.current) {
			chosenDerivMultiplier = derivMultiplier3;
			chosenMultiplier = multiplier3;
		}

		newPipValueRef.current = pipValue * Number(chosenMultiplier);
		const riskPerPipMove = pipsSL.current * newPipValueRef.current;
		let tradeAmount = Number(riskAmountRef.current) / riskPerPipMove;

		if (chosenMultiplier !== 1) {
			tradeAmount /= chosenMultiplier;
		}

		setTradeAmount(tradeAmount.toFixed(2));
		tradeAmountRef.current = tradeAmount;
		console.log("tradeAmount", tradeAmount);
		slProfit.current = Number(riskAmountRef.current);

		tpProfit.current = pipsTP.current * newPipValueRef.current * tradeAmountRef.current;

		if (slRetCandlesRef.current) {
			slRetCandlesRef.current.setPrice(newStopLossPrice);
			slRetCandlesRef.current.setText(`SL: ${riskAmountRef.current} -- Pips: ${pipsSL.current.toFixed(2)} X: ${chosenDerivMultiplier}`);
			slRetCandlesRef.current.setQuantity(tradeAmount.toFixed(2));
		} else {
			slRetCandlesRef.current = tvWidget
				.chart()
				.createOrderLine()
				.setText(`SL: ${riskAmountRef.current} -- Pips: ${averageOpenCloseDifference.toFixed(2)} X: ${chosenDerivMultiplier}`)
				.setQuantity(tradeAmount)
				.setPrice(newStopLossPrice.toFixed(2))
				.setLineColor("#f74712")
				.setQuantityBorderColor("#f74712")
				.setBodyTextColor("#f74712")
				.setBodyBorderColor("#f74712")
				.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
				.setQuantityBackgroundColor("rgba(0, 0, 0, 0)")
				.setCancelButtonBackgroundColor("rgba(0, 0, 0, 0)")
				.setCancelButtonIconColor("#f74712")
				.setCancelButtonBorderColor("#f74712");

			slRetCandlesRef.current.setQuantity(tradeAmount.toFixed(2));
		}

		if (tpRetCandlesRef.current) {
			tpRetCandlesRef.current.setPrice(newTakeProfitPrice);
			tpRetCandlesRef.current.setText(`TP: $${Number(tpProfit.current).toFixed(2)} -- Pips: ${Number(pipsTP.current).toFixed(2)}`);
			tpRetCandlesRef.current.setQuantity(tradeAmount.toFixed(2));
		} else {
			tpRetCandlesRef.current = tvWidget
				.chart()
				.createOrderLine()
				.onMove(function () {
					const priceNew = Number(lastBarRef.current.close);
					const currentPrice = this.getPrice();
					pipsTP.current = Math.abs(priceNew - currentPrice);

					console.log("TP PROFIT", pipsTP.current, newPipValueRef.current, tradeAmountRef.current);
					tpProfit.current = pipsTP.current * newPipValueRef.current * tradeAmountRef.current;
					this.setText(`TP: $${tpProfit.current.toFixed(2)} -- Pips: ${pipsTP.current.toFixed(2)}`);
				})
				.setText(`TP: ${tpProfit.current} -- Pips: ${pipsTP.current.toFixed(2)} X: ${chosenDerivMultiplier}`)
				.setQuantity(tradeAmount.toFixed(2))
				.setPrice(newTakeProfitPrice.toFixed(2))
				.setLineColor("#35e737")
				.setQuantityBorderColor("#35e737")
				.setBodyBorderColor("#35e737")
				.setBodyTextColor("#35e737")
				.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
				.setQuantityBackgroundColor("rgba(0, 0, 0, 0)")
				.setCancelButtonBackgroundColor("rgba(0, 0, 0, 0)")
				.setCancelButtonIconColor("#35e737")
				.setCancelButtonBorderColor("#35e737");

			// tpRetCandlesRef.current.setQuantity(tradeAmount.toFixed(2));
		}
	};

	const setBreakEven = () => {
		const openPositions = store.getState().openPositions;
		const symbolInfo = store.getState().symbolInfo;

		if (!openPositions || !symbolInfo) return;

		openPositions.forEach((position) => {
			if (position.symbol === symbolInfo.symbol) {
				const contractId = position.contractId;
				let newStopLossPrice;

				if (position.type === "Buy") {
					newStopLossPrice = position.entryPrice + 1 * position.pip;

					if (lastBarRef.current.close < position.entryPrice) {
						return;
					}
				} else if (position.type === "Sell") {
					newStopLossPrice = position.entryPrice - 5 * position.pip;

					if (lastBarRef.current.close > position.entryPrice) {
						return;
					}
				} else {
					return;
				}

				const tradeAmount = position.tradeAmount;
				const stopOut = position.stopOut;
				const pipsSL = 5 * position.pip;
				const multiplier = position.multiplier;
				const slProfit = (tradeAmount / stopOut) * pipsSL * multiplier;

				const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
				updateStopLoss(contractId, newStopLossPrice, selectedAccount, pipsSL, slProfit);
			}
		});
	};

	const drawSLTPLines = () => {
		if (!tvWidget) return;
		if (slRetCandlesRef.current) {
			slRetCandlesRef.current.remove();
			slRetCandlesRef.current = null;
		}

		const percentageDistance = 0.001;
		if (lastBarRef.current == null) {
			setTimeout(() => {
				drawSLTPLines();
			}, 300);
			return;
		}
		const price = Number(lastBarRef.current.close);
		const distance = price * percentageDistance;

		SL.current = price + distance;
		pipsSL.current = Math.abs(price - SL.current);

		TP.current = price - distance;
		pipsTP.current = Math.abs(price - TP.current);

		const stopOut = Number(symbolInfo.stopOut);
		const pipValue = Number(riskAmountRef.current) / stopOut;
		const riskPerPipMove = pipsSL.current * pipValue;
		const tradeAmount = Number(riskAmountRef.current) / riskPerPipMove;
		setTradeAmount(tradeAmount.toFixed(2));

		if (slLineRef.current) {
			slLineRef.current.remove();
			slLineRef.current = null;
			setSlTpButton(false);
			setRetCandles(true);
		} else {
			setSlTpButton(true);
			setRetCandles(false);
			setPreStopLoss(null);
			slLineRef.current = tvWidget
				.chart()
				.createOrderLine()
				.onMove(function () {
					const priceNew = Number(lastBarRef.current.close);
					SL.current = slLineRef.current.getPrice();
					pipsSL.current = Math.abs(priceNew - SL.current);

					const derivMultiplier = symbolInfo.derivMultiplier;
					const multiplier2 = symbolInfo.multiplier2;
					const derivMultiplier2 = symbolInfo.derivMultiplier2;
					const multiplier3 = symbolInfo.multiplier3;
					const derivMultiplier3 = symbolInfo.derivMultiplier3;
					const stopOut = Number(symbolInfo.stopOut);
					const pipValue = Number(symbolInfo.pipValue);
					const riskPerPipMove = pipsSL.current * pipValue;
					const tradeAmount = Number(riskAmountRef.current) / riskPerPipMove;

					let chosenDerivMultiplier = derivMultiplier;
					let chosenMultiplier = 1;

					if (stopOut / multiplier2 > pipsSL.current) {
						chosenDerivMultiplier = derivMultiplier2;
						chosenMultiplier = multiplier2;
					}
					if (stopOut / multiplier3 > pipsSL.current) {
						chosenDerivMultiplier = derivMultiplier3;
						chosenMultiplier = multiplier3;
					}

					let adjustedTradeAmount = tradeAmount;

					if (chosenDerivMultiplier === derivMultiplier2) {
						adjustedTradeAmount = tradeAmount / multiplier2;
					} else if (chosenDerivMultiplier === derivMultiplier3) {
						adjustedTradeAmount = tradeAmount / multiplier3;
					}
					multiplierRef.current = chosenMultiplier;
					derivMultiplierRef.current = chosenDerivMultiplier;

					tradeAmountRef.current = adjustedTradeAmount.toFixed(2);
					setTradeAmount(adjustedTradeAmount.toFixed(2));
					this.setQuantity(Number(adjustedTradeAmount).toFixed(2));

					slProfit.current = Number(riskAmountRef.current);
					this.setText(`SL: $${riskAmountRef.current} -- Pips: ${pipsSL.current.toFixed(2)} X: ${chosenDerivMultiplier}`);

					if (symbolInfo.derivMultiplier !== derivMultiplierRef.current) {
						newPipValueRef.current = pipValue * multiplierRef.current;
					} else {
						newPipValueRef.current = pipValue;
					}

					console.log("NEW PIPVALUE", newPipValueRef.current);
					console.log("CHOSEN MULTIPLIER", derivMultiplierRef.current);

					tpProfit.current = pipsTP.current * newPipValueRef.current * adjustedTradeAmount;
					tpLineRef.current.setText(`TP: $${tpProfit.current.toFixed(2)} -- Pips: ${pipsTP.current.toFixed(2)}`);

					if (tpLineRef.current) {
						tpLineRef.current.setQuantity(Number(adjustedTradeAmount).toFixed(2));
					}
				})
				.onCancel(function () {})
				.setText(`SL: $${riskAmountRef.current} -- Pips: ${pipsSL.current.toFixed(2)}  X: ${derivMultiplierRef.current}`)
				.setQuantity(Number(tradeAmount).toFixed(2))
				.setPrice(SL.current)
				.setLineColor("#f74712")
				.setQuantityBorderColor("#f74712")
				.setBodyTextColor("#f74712")
				.setBodyBorderColor("#f74712")
				.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
				.setQuantityBackgroundColor("rgba(0, 0, 0, 0)")
				.setCancelButtonBackgroundColor("rgba(0, 0, 0, 0)")
				.setCancelButtonIconColor("#f74712")
				.setCancelButtonBorderColor("#f74712");
		}

		if (tpLineRef.current) {
			tpLineRef.current.remove();
			tpLineRef.current = null;
			setSlTpButton(false);
			setRetCandles(true);
		} else {
			setSlTpButton(true);
			setRetCandles(false);
			setPreStopLoss(null);
			tpLineRef.current = tvWidget
				.chart()
				.createOrderLine()
				.onMove(function () {
					const priceNew = Number(lastBarRef.current.close);
					TP.current = tpLineRef.current.getPrice();
					pipsTP.current = Math.abs(priceNew - TP.current);

					console.log("TP PROFIT", pipsTP.current, newPipValueRef.current, tradeAmountRef.current);
					tpProfit.current = pipsTP.current * newPipValueRef.current * tradeAmountRef.current;
					this.setText(`TP: $${tpProfit.current.toFixed(2)} -- Pips: ${pipsTP.current.toFixed(2)}`);
				})
				.onCancel(function () {})
				.setText(`TP: $${riskAmount} -- Pips: ${pipsTP.current.toFixed(2)}`)
				.setQuantity(Number(tradeAmount).toFixed(2))
				.setPrice(TP.current)
				.setLineColor("#35e737")
				.setQuantityBorderColor("#35e737")
				.setBodyBorderColor("#35e737")
				.setBodyTextColor("#35e737")
				.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
				.setQuantityBackgroundColor("rgba(0, 0, 0, 0)")
				.setCancelButtonBackgroundColor("rgba(0, 0, 0, 0)")
				.setCancelButtonIconColor("#35e737")
				.setCancelButtonBorderColor("#35e737");

			tpLineRef.current.setQuantity(riskAmountRef.current);
		}
	};

	const drawTrailingStop = () => {
		const tvWidget = store.getState().chart;
		const openPositions = store.getState().openPositions;
		const symbolInfo = store.getState().symbolInfo;

		if (!openPositions || !symbolInfo) return;

		openPositions.forEach((position) => {
			if (position.symbol === symbolInfo.symbol) {
				const contractId = position.contractId;

				if (lastBarRef.current === null) {
					setTimeout(() => {
						drawTrailingStop();
					}, 250);
					return;
				}
				const currentPrice = Number(lastBarRef.current.close);
				if (position.trailingStop === true) {
					trailingDistanceRef.current = Number(position.trailingStopDistance);
				}

				trailingDistanceRef.current = trailingDistanceRef.current
					? trailingDistanceRef.current
					: Math.abs(Number(position.stopLoss) - Number(position.entryPrice)) / 2;

				// if (position.type === "Sell" && currentPrice >= Number(position.entryPrice) - trailingDistanceRef.current) {
				// 	toast.error("Necesita tener mas Profit para activar el Trailing Stop");
				// 	stopTrailingStopInterval();
				// 	return;
				// } else if (position.type === "Buy" && currentPrice <= Number(position.entryPrice) + trailingDistanceRef.current) {
				// 	toast.error("Necesita tener mas Profit para activar el Trailing Stop");
				// 	stopTrailingStopInterval();
				// 	return;
				// }

				let trailingStopPrice = trailingLineRef.current
					? trailingLineRef.current.getPrice()
					: position.type === "Sell"
					? currentPrice + trailingDistanceRef.current
					: currentPrice - trailingDistanceRef.current;
				lastTrailingStopPriceRef.current = lastTrailingStopPriceRef.current ? lastTrailingStopPriceRef.current : trailingStopPrice;

				if (position.type === "Sell") {
					const difference = trailingStopPrice - currentPrice;
					if (difference > 0 && difference > trailingDistanceRef.current && lastTrailingStopPriceRef.current >= trailingStopPrice) {
						trailingStopPrice = currentPrice + trailingDistanceRef.current;
					}
				} else if (position.type === "Buy") {
					const difference = currentPrice - trailingStopPrice;
					if (difference > 0 && difference > trailingDistanceRef.current && lastTrailingStopPriceRef.current <= trailingStopPrice) {
						trailingStopPrice = currentPrice - trailingDistanceRef.current;
					}
				}

				if (trailingStopPrice === null) return;
				lastTrailingStopPriceRef.current = trailingStopPrice;
				const tradeAmount = Number(position.tradeAmount);
				const pipsSL = Math.abs(trailingStopPrice - Number(position.entryPrice));
				const slProfit = pipsSL * Number(position.pipValue) * tradeAmount;

				if (trailingLineRef.current) {
					trailingLineRef.current
						.setPrice(trailingStopPrice)
						.setText(`TS: ${slProfit.toFixed(2)} -- Pips: ${trailingDistanceRef.current.toFixed(2)}`)
						.setQuantity(tradeAmount.toFixed(2));
				} else {
					trailingLineRef.current = tvWidget
						.chart()
						.createOrderLine()
						.onMove(async function () {
							const newLinePrice = this.getPrice();
							const currentPrice = Number(lastBarRef.current.close);
							const newDistance = Math.abs(currentPrice - newLinePrice);
							trailingDistanceRef.current = newDistance;
							const openPositions = store.getState().openPositions;
							const filteredPosition = await openPositions.find((pos) => pos.symbol === symbolInfoRef.current.symbol);
							filteredPosition.trailingStopDistance = newDistance;

							const trailingStopPrice = this.getPrice();
							const pipsSL = Math.abs(trailingStopPrice - Number(filteredPosition.entryPrice));
							const slProfit = pipsSL * Number(filteredPosition.pipValue) * Number(filteredPosition.tradeAmount);
							this.setText(`TS: ${slProfit.toFixed(2)} -- Pips: ${trailingDistanceRef.current.toFixed(2)}`);

							if (filteredPosition) {
								activateTrailing(filteredPosition, newDistance.toFixed(2));
							}
						})
						.onCancel(function () {
							stopTrailingStopInterval();
						})
						.setText(`TS: ${slProfit.toFixed(2)} -- Pips: ${pipsSL.toFixed(2)}`)
						.setQuantity(tradeAmount.toFixed(2))
						.setPrice(trailingStopPrice)
						.setTooltip(contractId)
						.setLineColor("#FFA500")
						.setQuantityBorderColor("#FFA500")
						.setBodyTextColor("#FFA500")
						.setBodyBorderColor("#FFA500")
						.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
						.setQuantityBackgroundColor("rgba(0, 0, 0, 0)")
						.setCancelButtonBackgroundColor("rgba(0, 0, 0, 0)")
						.setCancelButtonIconColor("#FFA500")
						.setCancelButtonBorderColor("#FFA500");
				}
			}
		});
	};

	const startTrailingStopInterval0 = async (t, position) => {
		setTrailingStop(t);
		if (t) {
			if (!trailingIntervalRef.current) {
				trailingIntervalRef.current = setInterval(drawTrailingStop, 1000);
			}
		} else {
			stopTrailingStopInterval(position);
		}
	};

	const startTrailingStopInterval = async (t) => {
		setTrailingStop(t);
		const openPositions = store.getState().openPositions;
		const filteredPosition = await openPositions.find((pos) => pos.symbol === symbolInfoRef.current.symbol);
		if (t) {
			if (!trailingIntervalRef.current) {
				trailingIntervalRef.current = setInterval(drawTrailingStop, 1000);
			}

			if (filteredPosition) {
				const dif = Math.abs(Number(filteredPosition.stopLoss) - Number(filteredPosition.entryPrice)) / 2;
				activateTrailing(filteredPosition, dif.toFixed(2));
			}
		} else {
			if (filteredPosition) {
				const dif = Math.abs(Number(filteredPosition.stopLoss) - Number(filteredPosition.entryPrice)) / 2;
				stopTrailingStopInterval(filteredPosition);
			}
		}
	};

	const stopTrailingStopInterval = (position) => {
		if (trailingIntervalRef.current) {
			clearInterval(trailingIntervalRef.current);
			trailingIntervalRef.current = null;
		}
		if (trailingLineRef.current) {
			trailingLineRef.current.remove();
			trailingLineRef.current = null;
		}
		setTrailingStop(false);
		deActivateTrailing(position);
	};

	const activateTrailing = (position, distance) => {
		const ts = {
			position,
			distance,
			activate: true,
		};
		console.log("ACTIVE TRAILING", ts);
		socket.emit("trailingStop", ts);
	};

	const deActivateTrailing = (position) => {
		const ts = {
			position,
			distance: 0,
			activate: false,
		};
		socket.emit("trailingStop", ts);
	};

	const drawBarrierLine = () => {
		const tvWidget = store.getState().chart;

		if (lastBarRef.current !== null && tvWidget) {
			const barrier = Number(barrierRef.current);
			const currentPrice = Number(lastBarRef.current.close);
			const newPrice = currentPrice + barrier;

			if (barrierLineRef.current) {
				barrierLineRef.current.setPrice(newPrice).setText(`Barrera`).setQuantity(barrier);
			} else {
				barrierLineRef.current = tvWidget
					.chart()
					.createOrderLine()
					.onMove(function () {
						const newLinePrice = this.getPrice();
						const currentPrice = lastBarRef.current.close;
						const newDistance = newLinePrice - currentPrice;
						updateBarrier(newDistance);
					})
					.setText(`Barrera`)
					.setQuantity(barrier)
					.setPrice(newPrice)
					.setLineColor("#4CAF50")
					.setBodyTextColor("#4CAF50")
					.setBodyBackgroundColor("rgba(0, 0, 0, 0)")
					.setQuantityBackgroundColor("rgba(0, 0, 0, 0)");
			}
		}
	};

	const startBarrierInterval = () => {
		if (!barrierIntervalRef.current) {
			barrierIntervalRef.current = setInterval(drawBarrierLine, 1000);
		}
	};

	const stopBarrierInterval = () => {
		if (barrierIntervalRef.current) {
			clearInterval(barrierIntervalRef.current);
			barrierIntervalRef.current = null;
		}
		if (barrierLineRef.current) {
			barrierLineRef.current.remove();
			barrierLineRef.current = null;
		}
	};

	const updateBarrier = async (distance) => {
		if (distance !== "" && distance !== "0" && distance !== "0." && !distance.startsWith("+") && !distance.startsWith("-")) {
			toast.error("La barrera debe comenzar con '+' o '-'");
			return;
		}
		setBarrier(distance);
		await localStorage.setItem("barrier", distance);
		barrierRef.current = distance;

		if (distance !== "" && distance !== "0" && distance !== "0.") {
			handleProposal();
		}
	};

	const handleContractType = (optType) => {
		console.log("optType", optType);
		if (optType === "riseFall" || optType === "accumulators") {
			stopBarrierInterval();
		}
		if (optType !== "accumulators") {
			console.log("! ACCUMULATORS");
			removeAccumLines();
		}
		setContractType(optType);
		contractTypeRef.current = optType;
		localStorage.setItem("optionType", optType);
		handleProposal();
	};

	const handleProposal = async () => {
		try {
			if (symbolInfoRef.current == null && sliderRef.current == null) {
				setTimeout(() => {
					handleProposal();
				}, 300);
			}
			let callMessage = {};
			let putMessage = {};

			getSymbols();

			if (contractTypeRef.current === "riseFall") {
				callMessage = JSON.stringify({
					proposal: 1,
					amount: Number(riskAmountRef.current),
					basis: "stake",
					contract_type: "CALL",
					currency: "USD",
					duration: Number(sliderRef.current),
					duration_unit: "t",
					symbol: symbolInfoRef.current.symbol,
				});
			} else if (contractTypeRef.current === "higherLower") {
				const newValue = Math.max(5, sliderRef.current);
				setSliderValue(newValue);
				sliderRef.current = newValue;
				callMessage = JSON.stringify({
					proposal: 1,
					amount: Number(riskAmountRef.current),
					barrier: barrierRef.current,
					basis: "stake",
					contract_type: "CALL",
					currency: "USD",
					duration: Number(sliderRef.current),
					duration_unit: "t",
					symbol: symbolInfoRef.current.symbol,
				});
			} else if (contractTypeRef.current === "touchNoTouch") {
				callMessage = JSON.stringify({
					proposal: 1,
					amount: Number(riskAmountRef.current),
					barrier: barrierRef.current ? barrierRef.current : null,
					basis: "stake",
					contract_type: "ONETOUCH",
					currency: "USD",
					duration: Number(sliderRef.current),
					duration_unit: "t",
					symbol: symbolInfoRef.current.symbol,
				});
			} else if (contractTypeRef.current === "accumulators") {
				const proposalId = store.getState().proposalId;
				if (proposalId !== null) {
					derivWS.send(JSON.stringify({ forget: proposalId }));
					store.getState().setProposalId(null);
				}

				const newValue = Math.min(sliderRef.current, 5);
				await localStorage.setItem("sliderValue", newValue.toString());
				setSliderValue(newValue);
				sliderRef.current = Number(newValue);
				const rate = Number(newValue) / 100;

				callMessage = JSON.stringify({
					proposal: 1,
					amount: Number(riskAmountRef.current) === 0 ? 1 : Number(riskAmountRef.current),
					growth_rate: rate,
					basis: "stake",
					contract_type: "ACCU",
					currency: "USD",
					symbol: symbolInfoRef.current.symbol,
					subscribe: 1,
					passthrough: { accumulators: true },
				});

				callPayoutRef.current = "";
				setCallPayout(callPayoutRef.current);
				putPayoutRef.current = "";
				setPutPayout(putPayoutRef.current);
			}
			await derivWS.send(callMessage);
			const callProposal = await waitForMessageOfType("proposal");
			// console.log("CALL PROPOSAL 1", callProposal);

			if (contractTypeRef.current !== "accumulators") {
				const cp = Number(callProposal.proposal.payout) - Number(riskAmountRef.current);
				callPayoutRef.current = cp.toFixed(2);
				console.log("CALL PAYOUT", callPayoutRef.current);
				setCallPayout(callPayoutRef.current);
			}

			if (contractTypeRef.current !== "accumulators") {
				setTimeout(async () => {
					if (contractTypeRef.current === "riseFall") {
						putMessage = JSON.stringify({
							proposal: 1,
							amount: Number(riskAmountRef.current),
							basis: "stake",
							contract_type: "PUT",
							currency: "USD",
							duration: Number(sliderRef.current),
							duration_unit: "t",
							symbol: symbolInfoRef.current.symbol,
						});
					} else if (contractTypeRef.current === "higherLower") {
						putMessage = JSON.stringify({
							proposal: 1,
							amount: Number(riskAmountRef.current),
							barrier: barrierRef.current,
							basis: "stake",
							contract_type: "PUT",
							currency: "USD",
							duration: Number(sliderRef.current),
							duration_unit: "t",
							symbol: symbolInfoRef.current.symbol,
						});
					} else if (contractTypeRef.current === "touchNoTouch") {
						putMessage = JSON.stringify({
							proposal: 1,
							amount: Number(riskAmountRef.current),
							barrier: barrierRef.current ? barrierRef.current : null,
							basis: "stake",
							contract_type: "NOTOUCH",
							currency: "USD",
							duration: Number(sliderRef.current),
							duration_unit: "t",
							symbol: symbolInfoRef.current.symbol,
						});
					}
					await derivWS.send(putMessage);
					const putProposal = await waitForMessageOfType("proposal");
					// console.log("CALL PROPOSAL 2", callProposal);

					if (putProposal.error) {
						console.log(translations["Error:"], translations[putProposal.error.message] || putProposal.error.message);
					} else {
						if (contractTypeRef.current !== "accumulators") {
							const pp = Number(putProposal.proposal.payout) - Number(riskAmountRef.current);
							putPayoutRef.current = pp.toFixed(2);
							console.log("PUT PAYOUT", putPayoutRef.current);
							setPutPayout(putPayoutRef.current);
						}
					}
				}, 500);
			}
		} catch (error) {
			console.log("HANDLW PROPOSAL ERROR", error);
		}
	};

	useEffect(() => {
		const handleBuyOptions = (event) => {
			const type = event.detail;
			buyOptions(type);
		};
		window.addEventListener("buyOptions", handleBuyOptions);

		const handleCalculateAmount = (event) => {
			const percent = event.detail;
			// setRiskAmount(Number(percent).toFixed(2));
			riskAmountRef.current = Number(percent).toFixed(2);
		};
		window.addEventListener("setRiskAmount", handleCalculateAmount);

		const resetCalculateAmount = (event) => {
			riskAmountRef.current = Number(riskAmount).toFixed(2);
		};
		window.addEventListener("resetRiskAmount", resetCalculateAmount);

		return () => {
			window.removeEventListener("buyOptions", handleBuyOptions);
			window.removeEventListener("setRiskAmount", handleCalculateAmount);
			window.removeEventListener("resetRiskAmount", resetCalculateAmount);
		};
	}, []);

	const toggleLock = () => {
		setIsLocked(!isLocked);
		setDragging(!isLocked);
	};

	return (
		<ThemeProvider theme={theme}>
			{chartType === "Multiplicadores" && (
				<Rnd
					position={position}
					onDragStop={(e, d) => {
						const newPosition = { x: d.x, y: d.y };
						setPosition(newPosition);
						localStorage.setItem("windowPosition", JSON.stringify(newPosition)); // Guarda en localStorage
						toggleLock();
					}}
					enableUserSelectHack={false}
					enableResizing={false}
					bounds="window"
					onDoubleClick={toggleLock}
					disableDragging={dragging}>
					<div
						onClick={toggleLock}
						style={{
							position: "absolute",
							top: "-5px",
							right: "-15px",
							cursor: "pointer",
							zIndex: 1000,
							color: "#fff", // Change color as needed
						}}>
						{isLocked ? <LockOutlinedIcon style={{ fontSize: "16px" }} /> : <LockOpenOutlinedIcon style={{ fontSize: "16px" }} />}
					</div>

					{positionType === "multipliers" && (
						<Card
							sx={{
								backgroundColor: "rgba(23, 27, 38, 0.9)",
								height: "fit-content",
								width: "17rem",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
								gap: "0.5rem",
								padding: "5px",
								borderRadius: "10px",
								boxShadow:
									selectedAccount && selectedAccount.account && selectedAccount.account.startsWith("CR")
										? "0 0 5px #4599d9, 0 0 30px #4599d9"
										: "0 0 5px #f74712, 0 0 30px #f74712",
								borderWidth: "1px",
								borderStyle: "solid",
								borderColor:
									selectedAccount && selectedAccount.account && selectedAccount.account.startsWith("CR") ? "#4599d9" : "#f74712",
							}}>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "center",
									alignItems: "center",
									gap: "0.5rem",
								}}>
								<MUIButton
									variant="contained"
									sx={{
										height: "1.8rem",
										width: retCandles ? "2.5rem" : "4.5rem",
										fontWeight: "bold",
										fontFamily: "'Orbitron', sans-serif",
										fontSize: "0.8rem",
										color: "#ffffff",
										backgroundColor: "#f74712", // Changed from error color to a custom #f74712
										textShadow: "0 0 5px #fff",
										boxShadow: "0 0 10px #f74712, 0 0 20px #f74712",
										"&:hover": {
											backgroundColor: "#d93d0d", // Darker shade for hover effect
										},
										"@media (max-width: 768px)": {
											fontSize: "10px",
											height: "1.5rem",
											width: "3rem",
										},
									}}
									onClick={() => buyMultUpMultDn("MULTDOWN")}>
									Sell
								</MUIButton>

								<TextField
									variant="outlined"
									label="Riesgo"
									value={riskAmount}
									onChange={(e) => {
										const value = e.target.value.replace(",", ".");
										if (/^\d*\.?\d*$/.test(value)) {
											setRiskAmount(value);
											riskAmountRef.current = value;
											localStorage.setItem("riskAmount", value);
											if (slLineRef.current) {
												slLineRef.current.setText(`SL: ${riskAmountRef.current} -- Pips: ${pipsSL.current.toFixed(2)}`);
											}
										}
									}}
									// disabled={retCandles}
									sx={{
										width: "4rem",
										"& .MuiOutlinedInput-root": {
											"& input": {
												color: "#ffffff",
												fontSize: "0.75rem",
												padding: "6px 14px",
											},
											"& fieldset": {
												borderColor: "#4599d9",
											},
											"&:hover fieldset": {
												borderColor: "#f74712",
											},
										},
										"& .MuiInputLabel-root": {
											color: "#ffffff",
											fontSize: "0.75rem",
										},
									}}
								/>
								{retCandles && (
									<TextField
										variant="outlined"
										fullWidth
										label={!retCandles ? "Ret Candles" : "Porcentaje (%)"}
										value={!retCandles ? retCandlesQty : inputValue}
										onChange={(t) => {
											if (!retCandles) {
												handleInputRetCandles(t);
											} else {
												handleInputChange(t);
											}
										}}
										sx={{
											width: "5rem",
											margin: "0.2rem",
											input: {
												color: "#ffffff",
												fontSize: "0.75rem",
												padding: "6px 14px",
											},
											"& .MuiOutlinedInput-root": {
												"& fieldset": {
													borderColor: "#4599d9",
												},
												"&:hover fieldset": {
													borderColor: "#f74712",
												},
											},
											"& .MuiInputLabel-root": {
												color: "#ffffff",
												fontSize: "0.75rem",
											},
										}}
									/>
								)}
								<MUIButton
									variant="contained"
									sx={{
										height: "1.8rem",
										width: retCandles ? "2.5rem" : "4.5rem",
										fontWeight: "bold",
										fontFamily: "'Orbitron', sans-serif",
										fontSize: "0.8rem",
										color: "#ffffff",
										backgroundColor: "#4599d9",
										textShadow: "0 0 5px #fff",
										boxShadow: "0 0 5px #4599d9, 0 0 10px #4599d9",
										"&:hover": {
											backgroundColor: "#4599d9",
										},
										"@media (max-width: 768px)": {
											fontSize: "0.8rem",
											height: "1.5rem",
											width: "3rem",
										},
									}}
									onClick={() => buyMultUpMultDn("MULTUP")}>
									Buy
								</MUIButton>
							</Box>

							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "center",
									alignItems: "center",
									width: "100%",
								}}>
								<MUIButton
									variant="contained"
									sx={{
										width: "2rem",
										padding: "0.2rem",
										fontSize: "0.6rem",
										fontWeight: "bold",
										fontFamily: "'Orbitron', sans-serif",
										color: "#ffffff",
										margin: "0.1rem",
										lineHeight: "1",
										backgroundColor: trailingStop ? "#4599d9" : "#808080", // Cambiar color si está activo
										textShadow: "0 0 1px #fff",
										boxShadow: trailingStop ? "0 0 5px #4599d9, 0 0 10px #4599d9" : "0 0 5px #808080, 0 0 10px #808080",
										"&:hover": {
											backgroundColor: slTpButtonColor === "#808080" ? "#696969" : "#35e737",
											boxShadow: "0 0 15px #4599d9, 0 0 30px #4599d9",
										},
									}}
									onClick={() => startTrailingStopInterval(!trailingStop)}>
									TLN ST
								</MUIButton>

								<MUIButton
									variant="contained"
									sx={{
										width: "2rem",
										padding: "0.2rem",
										fontSize: "0.6rem",
										fontWeight: "bold",
										fontFamily: "'Orbitron', sans-serif",
										color: "#ffffff",
										margin: "0.2rem",
										lineHeight: "1",
										backgroundColor: slTpButtonColor,
										textShadow: "0 0 1px #fff",
										boxShadow:
											slTpButtonColor === "#808080" ? "0 0 5px #808080, 0 0 10px #808080" : "0 0 5px #4599d9, 0 0 10px #4599d9",
										"&:hover": {
											backgroundColor: slTpButtonColor === "#808080" ? "#696969" : "#35e737",
											boxShadow: "0 0 15px #4599d9, 0 0 30px #4599d9",
										},
									}}
									onClick={() => setBreakEven()}>
									BRK EVN
								</MUIButton>

								<TextField
									variant="outlined"
									fullWidth
									label={retCandles ? "Ret Candles" : "Porcentaje (%)"}
									value={retCandles ? retCandlesQty : inputValue}
									onChange={(t) => {
										if (retCandles) {
											handleInputRetCandles(t);
										} else {
											handleInputChange(t);
										}
									}}
									sx={{
										width: "5rem",
										margin: "0.2rem",
										input: {
											color: "#ffffff",
											fontSize: "0.75rem",
											padding: "6px 14px",
										},
										"& .MuiOutlinedInput-root": {
											"& fieldset": {
												borderColor: "#4599d9",
											},
											"&:hover fieldset": {
												borderColor: "#f74712",
											},
										},
										"& .MuiInputLabel-root": {
											color: "#ffffff",
											fontSize: "0.75rem",
										},
									}}
								/>

								<MUIButton
									variant="contained"
									sx={{
										width: "2.5rem",
										padding: "0.2rem",
										fontSize: "0.55rem",
										fontWeight: "bold",
										fontFamily: "'Orbitron', sans-serif",
										color: "#ffffff",
										margin: "0.2rem",
										lineHeight: "1",
										height: "2rem",
										backgroundColor: retCandles ? "#4599d9" : slTpButtonColor, // Cambiar color si está activo
										textShadow: "0 0 1px #fff",
										boxShadow: retCandles
											? "0 0 5px #4599d9, 0 0 10px #4599d9"
											: slTpButtonColor === "#808080"
											? "0 0 5px #808080, 0 0 10px #808080"
											: "0 0 5px #4599d9, 0 0 10px #4599d9",
										"&:hover": {
											backgroundColor: slTpButtonColor === "#808080" ? "#696969" : "#35e737",
											boxShadow: "0 0 15px #4599d9, 0 0 30px #4599d9",
										},
										"&:active": {
											backgroundColor: "#4599d9", // Color azul cuando el botón está activo
											boxShadow: "0 0 5px #4599d9, 0 0 10px #4599d9",
										},
									}}
									onClick={async () => {
										setRetCandles(true);
										setSlTpButton(false);
										if (slLineRef.current) {
											slLineRef.current.remove();
											slLineRef.current = null;
										}

										if (tpLineRef.current) {
											tpLineRef.current.remove();
											tpLineRef.current = null;
										}
									}}>
									Crash Boom
								</MUIButton>

								<MUIButton
									variant="contained"
									sx={{
										width: "2.5rem",
										padding: "0.2rem",
										fontSize: "0.55rem",
										fontWeight: "bold",
										fontFamily: "'Orbitron', sans-serif",
										color: "#ffffff",
										margin: "0.1rem",
										lineHeight: "1",
										height: "2rem",
										backgroundColor: slTpButton ? "#4599d9" : slTpButtonColor, // Cambiar color si está presionado
										textShadow: "0 0 1px #fff",
										boxShadow: slTpButton
											? "0 0 5px #4599d9, 0 0 10px #4599d9"
											: slTpButtonColor === "#808080"
											? "0 0 5px #808080, 0 0 10px #808080"
											: "0 0 5px #4599d9, 0 0 10px #4599d9",
										"&:hover": {
											backgroundColor: slTpButtonColor === "#808080" ? "#696969" : "#35e737",
											boxShadow: "0 0 15px #4599d9, 0 0 30px #4599d9",
										},
										"&:active": {
											backgroundColor: "#4599d9", // Color azul cuando el botón está activo
											boxShadow: "0 0 5px #4599d9, 0 0 10px #4599d9",
										},
									}}
									onClick={() => drawSLTPLines()}>
									SL/TP
								</MUIButton>
							</Box>
						</Card>
					)}
				</Rnd>
			)}

			{chartType === "Opciones" && (
				<Rnd
					position={position}
					onDragStop={(e, d) => {
						const newPosition = { x: d.x, y: d.y };
						setPosition(newPosition);
						localStorage.setItem("windowPosition", JSON.stringify(newPosition));
						toggleLock();
					}}
					onDoubleClick={toggleLock}
					enableUserSelectHack={false}
					enableResizing={false}
					bounds="window"
					disableDragging={dragging}>
					<div
						onClick={toggleLock}
						style={{
							position: "absolute",
							top: "25px",
							right: "15px",
							cursor: "pointer",
							zIndex: 1000,
							color: "#fff", // Change color as needed
						}}>
						{isLocked ? <LockOutlinedIcon style={{ fontSize: "16px" }} /> : <LockOpenOutlinedIcon style={{ fontSize: "16px" }} />}
					</div>
					<Box sx={{ padding: "2rem", backgroundColor: "transparent" }}>
						<Box
							sx={{
								backgroundColor: "rgba(23, 27, 38, 0.9)",
								height: "fit-content",
								width: "23rem",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
								gap: "0.5rem",
								padding: "5px",
								borderRadius: "10px",
								boxShadow:
									selectedAccount && selectedAccount.account && selectedAccount.account.startsWith("CR")
										? "0 0 5px #4599d9, 0 0 10px #4599d9"
										: "0 0 5px #f74712, 0 0 10px #f74712",
								borderWidth: "1px",
								borderStyle: "solid",
								borderColor:
									selectedAccount && selectedAccount.account && selectedAccount.account.startsWith("CR") ? "#4599d9" : "#f74712",
							}}>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "center",
									alignItems: "center",
									gap: "0.5rem",
								}}>
								<MUIButton
									variant="contained"
									sx={{
										height: "2.2rem",
										width: "5.5rem",
										fontWeight: "bold",
										fontSize: "0.8rem",
										fontFamily: "'Orbitron', sans-serif",
										color: "#ffffff",
										backgroundColor: "#4599d9",
										textShadow: "0 0 5px #fff",
										boxShadow: "0 0 10px #4599d9, 0 0 20px #4599d9",
										"&:hover": {
											backgroundColor: "#4599d9", // Darker shade for hover effect
										},
										"@media (max-width: 768px)": {
											fontSize: "10px",
											height: "1.5rem",
											width: "3rem",
										},
									}}
									onClick={() => {
										let action = null;
										contractType === "riseFall"
											? (action = "CALL")
											: contractType === "higherLower"
											? (action = "CALL")
											: contractType === "touchNoTouch"
											? (action = "ONETOUCH")
											: contractType === "accumulators"
											? (action = "ACCU")
											: (action = null);
										buyOptions(action);
									}}>
									{contractType === "riseFall"
										? "RISE"
										: contractType === "higherLower"
										? "HIGHER"
										: contractType === "touchNoTouch"
										? "TOUCH"
										: contractType === "accumulators"
										? "COMPRAR"
										: ""}
									<br />${callPayoutRef.current}
								</MUIButton>

								<TextField
									variant="outlined"
									label="Riesgo"
									value={riskAmount}
									onChange={(e) => {
										const value = e.target.value.replace(",", ".");
										if (/^\d*\.?\d*$/.test(value)) {
											setRiskAmount(value);
											handleProposal();
											riskAmountRef.current = value;
											localStorage.setItem("riskAmount", value);
											if (slLineRef.current) {
												slLineRef.current.setText(`SL: ${riskAmountRef.current} -- Pips: ${pipsSL.current.toFixed(2)}`);
											}
										}
									}}
									// disabled={retCandles}
									sx={{
										width: "4rem",
										"& .MuiOutlinedInput-root": {
											"& input": {
												color: "#ffffff",
												fontSize: "0.75rem",
												padding: "6px 14px",
											},
											"& fieldset": {
												borderColor: "#4599d9",
											},
											"&:hover fieldset": {
												borderColor: "#f74712",
											},
										},
										"& .MuiInputLabel-root": {
											color: "#ffffff",
											fontSize: "0.75rem",
										},
									}}
								/>
								<TextField
									variant="outlined"
									fullWidth
									label="Porcentaje (%)"
									value={retCandles ? retCandlesQty : inputValue}
									onChange={(t) => {
										if (retCandles) {
											handleInputRetCandles(t);
										} else {
											handleInputChange(t);
										}
									}}
									sx={{
										width: "5rem",
										margin: "0.2rem",
										input: {
											color: "#ffffff",
											fontSize: "0.75rem",
											padding: "6px 14px",
										},
										"& .MuiOutlinedInput-root": {
											"& fieldset": {
												borderColor: "#4599d9",
											},
											"&:hover fieldset": {
												borderColor: "#f74712",
											},
										},
										"& .MuiInputLabel-root": {
											color: "#ffffff",
											fontSize: "0.75rem",
										},
									}}
								/>

								<MUIButton
									variant="contained"
									sx={{
										height: "2.2rem",
										width: "5.5rem",
										fontWeight: "bold",
										fontSize: "0.8rem",
										fontFamily: "'Orbitron', sans-serif",
										color: "#ffffff",
										backgroundColor: "#f74712",
										textShadow: "0 0 5px #fff",
										boxShadow: "0 0 5px #f74712, 0 0 10px #f74712",
										"&:hover": {
											backgroundColor: "#f74712",
										},
										"@media (max-width: 768px)": {
											fontSize: "0.8rem",
											height: "1.5rem",
											width: "3rem",
										},
									}}
									onClick={() => {
										let action = null;
										contractType === "riseFall"
											? (action = "PUT")
											: contractType === "higherLower"
											? (action = "PUT")
											: contractType === "touchNoTouch"
											? (action = "NOTOUCH")
											: contractType === "accumulators"
											? (action = "ACCUSELL")
											: (action = null);
										buyOptions(action);
									}}>
									{contractType === "riseFall"
										? "FALL"
										: contractType === "higherLower"
										? "LOWER"
										: contractType === "touchNoTouch"
										? "NOTOUCH"
										: contractType === "accumulators"
										? "VENDER"
										: ""}
									<br />${putPayoutRef.current}
								</MUIButton>
							</Box>

							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "center",
									alignItems: "center",
									width: "100%",
								}}>
								<Slider
									aria-label="Discrete slider"
									defaultValue={0}
									valueLabelDisplay="auto"
									step={1}
									marks
									min={contractTypeRef.current === "higherLower" ? 5 : 0}
									max={contractTypeRef.current === "accumulators" ? 5 : 10}
									value={sliderValue}
									onChange={async (e, newValue) => {
										if (contractTypeRef.current === "accumulators" && newValue === 0) {
											toast.error("El valor mínimo debe ser 1", {
												position: "top-right",
												autoClose: 2000,
												hideProgressBar: false,
												closeOnClick: true,
												pauseOnHover: true,
												draggable: true,
												theme: "dark",
											});
											setSliderValue(Number(sliderRef.current));
											return;
										}
										await localStorage.setItem("sliderValue", newValue.toString());
										setSliderValue(Number(newValue));
										sliderRef.current = newValue;
										handleProposal();
									}}
									sx={{
										width: "200px",
										color: "#4599d9",
										marginRight: "2rem",
										"& .MuiSlider-thumb": {
											backgroundColor: "#ffffff",
											color: "#4599d9",
											width: "25px",
											height: "25px",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											"&::after": {
												content: `"${sliderValue}"`,
												color: "#4599d9",
												fontSize: "16px",
												fontWeight: "bold",
												paddingTop: "0.6rem",
												paddingLeft: "1rem",
											},
										},
										"& .MuiSlider-valueLabel": {
											display: "none",
										},
										"& .MuiSlider-mark": {
											backgroundColor: "#ffffff",
										},
									}}
								/>

								{contractType !== "riseFall" && (
									<TextField
										variant="outlined"
										fullWidth
										label={contractTypeRef.current === "accumulators" ? "Profit Ticks" : "Barrera"}
										value={contractTypeRef.current === "accumulators" ? profitTick : barrier}
										onChange={async (t) => {
											const inputValue = t.target.value;
											if (contractTypeRef.current === "accumulators") {
												await localStorage.setItem("profitTick", inputValue);
												setProfitTick(inputValue);
											} else {
												updateBarrier(inputValue);
											}
										}}
										sx={{
											width: "5rem",
											margin: "0.2rem",
											input: {
												color: "#ffffff",
												fontSize: "0.75rem",
												padding: "6px 14px",
											},
											"& .MuiOutlinedInput-root": {
												"& fieldset": {
													borderColor: "#4599d9",
												},
												"&:hover fieldset": {
													borderColor: "#f74712",
												},
											},
											"& .MuiInputLabel-root": {
												color: "#ffffff",
												fontSize: "0.75rem",
											},
										}}
									/>
								)}
							</Box>
						</Box>

						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
								alignItems: "center",
								width: "100%",
								paddingTop: "0.4rem",
							}}>
							<MUIButton
								variant="contained"
								sx={{
									width: "3.5rem",
									padding: "0.2rem",
									fontSize: "0.7rem",
									fontWeight: "bold",
									fontFamily: "'Orbitron', sans-serif",
									color: "#ffffff",
									margin: "0.2rem",
									lineHeight: "1",
									height: "2rem",
									backgroundColor: contractType === "riseFall" ? "#4599d9" : slTpButtonColor,
									textShadow: "0 0 1px #fff",
									boxShadow:
										contractType === "riseFall"
											? "0 0 5px #4599d9, 0 0 10px #4599d9"
											: slTpButtonColor === "#808080"
											? "0 0 5px #808080, 0 0 10px #808080"
											: "0 0 5px #4599d9, 0 0 10px #4599d9",
									"&:hover": {
										backgroundColor: slTpButtonColor === "#808080" ? "#696969" : "#35e737",
										boxShadow: "0 0 15px #4599d9, 0 0 30px #4599d9",
									},
									"&:active": {
										backgroundColor: "#4599d9", // Color azul cuando el botón está activo
										boxShadow: "0 0 5px #4599d9, 0 0 10px #4599d9",
									},
								}}
								onClick={async () => {
									handleContractType("riseFall");
								}}>
								RISE FALL
							</MUIButton>
							<MUIButton
								variant="contained"
								sx={{
									width: "3.5rem",
									padding: "0.2rem",
									fontSize: "0.7rem",
									fontWeight: "bold",
									fontFamily: "'Orbitron', sans-serif",
									color: "#ffffff",
									margin: "0.2rem",
									lineHeight: "1",
									height: "2rem",
									backgroundColor: contractType === "higherLower" ? "#4599d9" : slTpButtonColor, // Cambiar color si está activo
									textShadow: "0 0 1px #fff",
									boxShadow:
										contractType === "higherLower"
											? "0 0 5px #4599d9, 0 0 10px #4599d9"
											: slTpButtonColor === "#808080"
											? "0 0 5px #808080, 0 0 10px #808080"
											: "0 0 5px #4599d9, 0 0 10px #4599d9",
									"&:hover": {
										backgroundColor: slTpButtonColor === "#808080" ? "#696969" : "#35e737",
										boxShadow: "0 0 15px #4599d9, 0 0 30px #4599d9",
									},
									"&:active": {
										backgroundColor: "#4599d9", // Color azul cuando el botón está activo
										boxShadow: "0 0 5px #4599d9, 0 0 10px #4599d9",
									},
								}}
								onClick={async () => {
									handleContractType("higherLower");
								}}>
								HIGHER LOWER
							</MUIButton>

							<MUIButton
								variant="contained"
								sx={{
									width: "3.5rem",
									padding: "0.2rem",
									fontSize: "0.6rem",
									fontWeight: "bold",
									fontFamily: "'Orbitron', sans-serif",
									color: "#ffffff",
									margin: "0.2rem",
									lineHeight: "1",
									height: "2rem",
									backgroundColor: contractType === "touchNoTouch" ? "#4599d9" : slTpButtonColor, // Cambiar color si está activo
									textShadow: "0 0 1px #fff",
									boxShadow:
										contractType === "touchNoTouch"
											? "0 0 5px #4599d9, 0 0 10px #4599d9"
											: slTpButtonColor === "#808080"
											? "0 0 5px #808080, 0 0 10px #808080"
											: "0 0 5px #4599d9, 0 0 10px #4599d9",
									"&:hover": {
										backgroundColor: slTpButtonColor === "#808080" ? "#696969" : "#35e737",
										boxShadow: "0 0 15px #4599d9, 0 0 30px #4599d9",
									},
									"&:active": {
										backgroundColor: "#4599d9", // Color azul cuando el botón está activo
										boxShadow: "0 0 5px #4599d9, 0 0 10px #4599d9",
									},
								}}
								onClick={async () => {
									handleContractType("touchNoTouch");
								}}>
								TOUCH NOTOUCH
							</MUIButton>

							<MUIButton
								variant="contained"
								sx={{
									width: "3.5rem",
									padding: "0.2rem",
									fontSize: "0.7rem",
									fontWeight: "bold",
									fontFamily: "'Orbitron', sans-serif",
									color: "#ffffff",
									margin: "0.1rem",
									lineHeight: "1",
									height: "2rem",
									backgroundColor: contractType === "accumulators" ? "#4599d9" : slTpButtonColor, // Cambiar color si está presionado
									textShadow: "0 0 1px #fff",
									boxShadow:
										contractType === "accumulators"
											? "0 0 5px #4599d9, 0 0 10px #4599d9"
											: slTpButtonColor === "#808080"
											? "0 0 5px #808080, 0 0 10px #808080"
											: "0 0 5px #4599d9, 0 0 10px #4599d9",
									"&:hover": {
										backgroundColor: slTpButtonColor === "#808080" ? "#696969" : "#35e737",
										boxShadow: "0 0 15px #4599d9, 0 0 30px #4599d9",
									},
									"&:active": {
										backgroundColor: "#4599d9", // Color azul cuando el botón está activo
										boxShadow: "0 0 5px #4599d9, 0 0 10px #4599d9",
									},
								}}
								onClick={() => handleContractType("accumulators")}>
								ACCUM
							</MUIButton>
						</Box>
					</Box>
				</Rnd>
			)}
		</ThemeProvider>
	);
}

export const buyOptions = MyDraggableComponent.buyOptions;
